import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, Link } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";
import Barcode from 'react-barcode';
import QRCode from "react-qr-code";

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label,
  Col, CardBody, CardTitle
} from "reactstrap";

import Header from "components/Headers/Header.js";

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const OrderIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [report, setReport] = useState({});
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');
  const [searchStatusShipping, setSearchStatusShipping] = useState('');
  const [searchStatusPayment, setSearchStatusPayment] = useState('');
  const [searchCategoryDate, setSearchCategoryDate] = useState('order_date');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [print, setPrint] = useState(false);
  const [staffId, setStaffId] = useState('');

  const [id, setId] = useState('');
  const [statusShipping, setStatusShipping] = useState('');
  const [statusPayment, setStatusPayment] = useState('');
  const [expeditionNumber, setExpeditionNumber] = useState('');
  const [subsidi, setSubsidi] = useState(0);
  const [expeditionName, setExpeditionName] = useState('');
  const [expeditionService, setExpeditionService] = useState('');
  const [shippingPrice, setShippingPrice] = useState('');
  const [selectOrder, setSelectOrder] = useState({});
  const [byStaffId, setByStaffId] = useState('');
  const [byStaffCommision, setByStaffCommision] = useState(0);
  const [byStaffCommisionRepeatOrder, setByStaffCommisionRepeatOrder] = useState(0);

  const [toAccountId, setToAccountId] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [customerPaymentMethod, setCustomerPaymentMethod] = useState('');
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [paidDate, setPaidDate] = useState('');
  const [paymentNumbers, setPaymentNumbers] = useState([]);
  const [customerServices, setCustomerServices] = useState([]);
  const [linkDownload, setLinkDownload] = useState('');
  const [loadingExport, setLoadingExport] = useState(false);
  const [modalExport, setModalExport] = useState(false);
  const toggleExport = () => {
    setModalExport(!modalExport);
  }
  const [level, setLevel] = useState('');

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = data.filter(o => o.id === id)[0]
      if(dt){
        setId(dt.id);
        setStatusShipping(dt.status_shipping);
        setStatusPayment(dt.status_payment);
        setExpeditionNumber(dt.expedition_number);
        setSubsidi(dt.subsidi);
        setExpeditionName(dt.expedition_name);
        setExpeditionService(dt.expedition_service);
        setShippingPrice(dt.shipping_price);
        setByStaffCommision(dt.staff_commision);
        setByStaffCommisionRepeatOrder(dt.staff_commision_repeat_order);
        setSelectOrder(dt);
        setImageSrc(dt.image_url);
        setImageFile('');
        setPaidDate(dt.paid_date);
        setToAccountId(dt.to_account_id);
        setStaffId(dt.staff_id);
      }else{
        setId('');
        setStatusShipping('');
        setStatusPayment('');
        setExpeditionNumber('');
        setSubsidi(0);
        setExpeditionName('');
        setExpeditionService('');
        setShippingPrice('');
        setByStaffCommision(0);
        setByStaffCommisionRepeatOrder(0);
        setSelectOrder({});
        setImageSrc();
        setImageFile('');
        setPaidDate('');
        setToAccountId('');
        setStaffId('');
      }
    }else{
      setId('');
      setStatusShipping('');
      setStatusPayment('');
      setExpeditionNumber('');
      setSubsidi(0);
      setExpeditionName('');
      setExpeditionService('');
      setShippingPrice('');
      setByStaffCommision(0);
      setByStaffCommisionRepeatOrder(0);
      setSelectOrder({});
      setImageSrc();
      setImageFile('');
      setPaidDate('');
      setToAccountId('');
      setStaffId('');
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const statusCollectionShipping = [
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Dikemas', 'value':'packing'},
    {'name':'Dikirim', 'value':'shipped'},
    {'name':'Diterima', 'value':'received'},
    {'name':'Dibatalkan', 'value':'cancelled'},
    {'name':'Diretur', 'value':'retur'}
  ]

  const statusCollectionPayment = [
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Dibayar', 'value':'paid'},
    {'name':'Dibatalkan', 'value':'cancelled'}
  ]

  const collectionPaymentMethod = [
    {'name':'Transfer Bank', 'value': 'Transfer Bank'},
    {'name':'COD', 'value':'COD'}
  ]

  const navCollection = [
    {'name':'Semua', 'value':''},
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Dikemas', 'value':'packing'},
    {'name':'Dikirim', 'value':'shipped'},
    {'name':'Diterima', 'value':'received'},
    {'name':'Dibatalkan', 'value':'cancelled'},
    {'name':'Diretur', 'value':'retur'}
  ]

  const categoryDate = [
    {'name':'Dipesan', 'value': 'order_date'},
    {'name':'Dibayar', 'value':'paid_date'},
    {'name':'Dikirim', 'value':'send_date'},
    {'name':'Diterima', 'value':'received_date'},
    {'name':'Diretur', 'value':'retur_date'}
  ]

  const deletePost = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.delete(`orders/${id}`, config )
      .then(res => {
        loadData('');
      })
      .catch(error => {
        setVisible(true);
        if(error === undefined){
          setErrorMessage('Gagal, Terjadi kesalahan di server')
        }else{
          setErrorMessage(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const loadData = (keyword, start_date, end_date, status_shipping, status_payment, payment_method, customer_payment_method, staff_id, level, category_date) => {    
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, include: 'order_detail, booking_order, room, user, expedition, staff_cs, order_item_codes', start_at: start_date, end_at: end_date, by_status_shipping: status_shipping, by_status_payment: (currentUser && currentUser.type === 'Merchant' ? 'paid' : status_payment), by_payment_method: payment_method, by_customer_payment_method: customer_payment_method, by_staff_id: staff_id, by_level: level, category_date: category_date }};
    API.get(`orders`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data)
        setSpinner(false)
        setLoadMore(false);
        setTotalCount(res.data.meta.pagination.total_entries);
        if(res.data.meta.pagination.total_pages <= 1){
          setPaging(false);
          console.log('No paging')
        }else{
          setPaging(true);
          console.log('Yes paging')
        }
      })

    API.get(`report_user`, config)
      .then(res => {
        const data = res.data.report;
        setReport(data)
      })
  }

  const loadMoreData = () => {
    setLoadMoreButton(true);
    setLoadMore(true);
    setOffset(offset+1)
    let offset_page = offset+1
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { page: offset_page, key: search, include: 'order_detail, booking_order, room, user, expedition, staff_cs, order_item_codes', start_at: searchStartDate, end_at: searchEndDate, by_status_shipping: searchStatusShipping, by_status_payment: (currentUser && currentUser.type === 'Merchant' ? 'paid' : searchStatusPayment), by_payment_method: paymentMethod, by_customer_payment_method: customerPaymentMethod, by_staff_id: byStaffId, by_level: level, category_date: searchCategoryDate } };
    
    API.get(`orders`, config)
      .then(res => {
        setLoadMore(false);
        const data2 = res.data.data;
        setData([...data, ...data2]);
        if(res.data.meta.pagination.total_pages === offset_page){
          setPaging(false);
        }else{
          setLoadMoreButton(false);
        }
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    loadData(search, searchStartDate, searchEndDate, searchStatusShipping, searchStatusPayment, paymentMethod, customerPaymentMethod, byStaffId, level, searchCategoryDate);
  }

  const updateSearchStartDate = (start_date) => {
    setSearchStartDate(start_date)
    loadData(search, start_date, searchEndDate, searchStatusShipping, searchStatusPayment, paymentMethod, customerPaymentMethod, byStaffId, level, searchCategoryDate);
  }

  const updateSearchEndDate = (end_date) => {
    setSearchEndDate(end_date)
    loadData(search, searchStartDate, end_date, searchStatusShipping, searchStatusPayment, paymentMethod, customerPaymentMethod, byStaffId, level, searchCategoryDate);
  }

  const statusTrackOrderSearch = (status) => {
    setSearchStatusShipping(status)
    loadData(search, searchStartDate, searchEndDate, status, searchStatusPayment, paymentMethod, customerPaymentMethod, byStaffId, level, searchCategoryDate);
  }

  const statusOrderPaymentSearch = (status) => {
    setSearchStatusPayment(status)
    loadData(search, searchStartDate, searchEndDate, searchStatusShipping, status, paymentMethod, customerPaymentMethod, byStaffId, level, searchCategoryDate);
  }

  const updateSearchPaymentMethod = (payment_method) => {
    setPaymentMethod(payment_method)
    loadData(search, searchStartDate, searchEndDate, searchStatusShipping, searchStatusPayment, payment_method, customerPaymentMethod, byStaffId, level, searchCategoryDate);
  }

  const updateSearchPaymentMethodCustomer = (customer_payment_method) => {
    setCustomerPaymentMethod(customer_payment_method)
    loadData(search, searchStartDate, searchEndDate, searchStatusShipping, searchStatusPayment, paymentMethod, customer_payment_method, byStaffId, level, searchCategoryDate);
  }

  const updateSearchStaffId = (staff_id) => {
    setOffset(1);
    setByStaffId(staff_id);
    loadData(search, searchStartDate, searchEndDate, searchStatusShipping, searchStatusPayment, paymentMethod, customerPaymentMethod, staff_id, level, searchCategoryDate);
  }

  const updateSearchLevel = (s) => {
    setOffset(1);

    if(s === '' ){
      setLevel('');
    }else{
      setLevel(s);
    }
    loadData(search, searchStartDate, searchEndDate, searchStatusShipping, searchStatusPayment, paymentMethod, customerPaymentMethod, byStaffId, s, searchCategoryDate);
  }

  const updateSearchCategoryDate = (category_date) => {
    setOffset(1);
    setSearchCategoryDate(category_date);
    loadData(search, searchStartDate, searchEndDate, searchStatusShipping, searchStatusPayment, paymentMethod, customerPaymentMethod, byStaffId, level, category_date);
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { headers: { Authorization: `Bearer ${token}` } };

    const fd = new FormData();
    fd.append('status_shipping', statusShipping);
    fd.append('status_payment', statusPayment);
    fd.append('expedition_number', expeditionNumber);
    fd.append('subsidi', subsidi);
    fd.append('expedition_name', expeditionName);
    fd.append('expedition_service', expeditionService);
    fd.append('shipping_price', shippingPrice);
    fd.append('paid_date', paidDate);
    fd.append('staff_commision', byStaffCommision);
    fd.append('staff_commision_repeat_order', byStaffCommisionRepeatOrder);
    fd.append('to_account_id', toAccountId);
    fd.append('staff_id', staffId);
    fd.append('include', 'order_detail, booking_order, room, user, order_item_codes');
    if(imageFile){
      fd.append('image_transfer', imageFile, imageFile.name);
    }
    
    if(id){
      API.put(`orders/${id}`, fd, config )
        .then(res => {
          const dt = res.data.data;
          const oldData = data;
          const new_data = oldData.findIndex((od) => od.id === dt.id)
          if (new_data !== -1){
            oldData[new_data] = dt
            setData(oldData);
          }
          setId(dt.id);
          setStatusShipping(dt.status_shipping);
          setStatusPayment(dt.status_payment);
          setExpeditionNumber(dt.expedition_number);
          setSubsidi(dt.subsidi);
          setExpeditionName(dt.expedition_name);
          setExpeditionService(dt.expedition_service);
          setShippingPrice(dt.shipping_price);
          setByStaffCommision(dt.staff_commision);
          setByStaffCommisionRepeatOrder(dt.staff_commision_repeat_order);
          setImageSrc(dt.image_url);
          setImageFile('');
          setPaidDate(dt.paid_date);
          setToAccountId(dt.to_account_id);
          setStaffId(dt.staff_id);
          // loadData(search, searchStartDate, searchEndDate, searchStatusShipping, searchStatusPayment, paymentMethod, customerPaymentMethod);
          setSpinnerSave(false);
          alert('Data berhasil di ubah');
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
    
  }

  const modePrint = () => {
    setPrint(!print);
  }

  const setCompanyLoad = (company_id) => {
    let config = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, by_company_id: company_id, by_user_type: 'Staff', by_role_access: 'cs', pagination: false }};
    API.get(`users`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCustomerServices(data);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })

    config = { headers: { Authorization: `Bearer ${token}`}, params: {by_use_payment: true, by_company_id: company_id, by_status: true, pagination: false }};
    API.get(`accounts`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPaymentNumbers(data);
      })
  }

  const exportData = () => {
    toggleExport();
    setLoadingExport(true);
    const config = { headers: { Authorization: `Bearer ${token}` }, params: { pagination: false, key: search, by_level: level, include: 'order_detail, booking_order, room, user, expedition, staff_cs, order_item_codes', start_at: searchStartDate, end_at: searchEndDate, by_status_shipping: searchStatusShipping, by_status_payment: (currentUser && currentUser.type === 'Merchant' ? 'paid' : searchStatusPayment), by_payment_method: paymentMethod, by_customer_payment_method: customerPaymentMethod, by_staff_id: byStaffId, by_level: level, category_date: searchCategoryDate } };
    API.get(`order_exports`, config)
      .then(res => {
        const data = res.data.report;
        setSpinner(false)
        setLoadingExport(false);
        toggleExport();
        setLinkDownload(data);
      })
      .catch(error => {
        setSpinner(false)
        setLoadingExport(false);
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    if(currentUser && currentUser.type === 'Company'){
      setCompanyLoad(currentUser.id);
    }else if (currentUser && currentUser.type === 'Staff'){
      setCompanyLoad(currentUser.company_id)
    }
    
    setSearch('');
    loadData('');
  
  },[])

  return (
    <>
      <Modal isOpen={modalExport} toggle={toggleExport}>
        <ModalHeader toggle={toggleExport}>Export Data Berhasil</ModalHeader>
        <ModalBody>
          { loadingExport ? (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>
              <Dots color='#fff' /> Loading
            </a>
          ) : (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>Download File</a>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={(e) => toggleExport(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>UBAH #{selectOrder.code}</ModalHeader>
        <ModalBody className='pt-0'>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                {
                  selectOrder.in_outlet === false && (selectOrder?.order_detail?.length > 0) ? (
                    <>
                      { currentUser && (currentUser.role_access === 'inventory' || currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster' || currentUser.type === 'Merchant') ? (
                        <>
                          <div className='col-6'>
                            <Label className='label-form-group'>Ubah Status {selectOrder.in_outlet === true ? 'Penerimaan' : 'Pengiriman'}</Label>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-truck" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="select" value={statusShipping} onChange={e => setStatusShipping(e.target.value)}>
                                <option value=''>Ubah Status</option>
                                {
                                  statusCollectionShipping.map((c) => {
                                    return (
                                      <option key={c.value} value={c.value}>{c.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </InputGroup>
                          </div>
                          <div className='col-6'>
                            <Label className='label-form-group'>Nomor Resi</Label>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-truck" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Nomor Resi"
                                value={expeditionNumber} onChange={e => setExpeditionNumber(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                          <div className='col-6'>
                            <Label className='label-form-group'>Expedisi Pengiriman</Label>
                            <InputGroup className="input-group-alternative">
                              <Input
                                placeholder="Nama Expedisi"
                                value={expeditionName} onChange={e => setExpeditionName(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                          <div className='col-6'>
                            <Label className='label-form-group'>Name Service Expedisi</Label>
                            <InputGroup className="input-group-alternative">
                              <Input
                                placeholder="Nama Service"
                                value={expeditionService} onChange={e => setExpeditionService(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                          { parseInt(shippingPrice) > 0 ? (
                            <div className='col-12'>
                              <Label className='label-form-group'>Ongkos Kirim</Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  placeholder="Nominal Ongkir"
                                  value={shippingPrice} onChange={e => setShippingPrice(e.target.value)}
                                />
                              </InputGroup>
                            </div>
                          ) : null }
                        </>
                      ) : null }
                    </>
                  ) : null
                }

                { currentUser && currentUser.type !== 'Merchant' ? (
                  <>
                    <div className='col-12'>
                      <center>
                        <br/>
                        <div>Upload Bukti Pembayaran</div>
                        <br/>
                        <FormGroup className="form-img-select">
                          <div className='box-image-select'>
                            <ImageUpload
                              handleImageSelect={handleImageSelect}
                              imageSrc={imageSrc}
                              setImageSrc={setImageSrc}
                              style={{
                                width: 120,
                                height: 120
                              }}
                            />
                          </div>
                        </FormGroup>
                      </center>
                    </div>
                    <div className='col-6'>
                      <FormGroup>
                        <Label className='label-form-group'>Tanggal Pembayaran</Label>
                        <DatePicker options={{ yearRange: 70}} id= "example-datepicker3" value={paidDate} onChange={e => setPaidDate(e)} />
                      </FormGroup>
                    </div>
                    <div className='col-6'>
                      <Label className='label-form-group'>Akun Penerimaan</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <Input type="select" value={toAccountId} onChange={e => setToAccountId(e.target.value)}>
                          <option value=''>Pilih No.Rekening</option>
                          {
                            paymentNumbers.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.title+'-'+c.code}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    </div>
                  </>
                ) : null }

                { currentUser && (currentUser.role_access === 'finance' || currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster') ? (
                  <>
                    <div className='col-6'>
                      <Label className='label-form-group'>Ubah Status Pembayaran</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-coins" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" value={statusPayment} onChange={e => setStatusPayment(e.target.value)}>
                          <option value=''>Ubah Status Pembayaran</option>
                          {
                            statusCollectionPayment.map((c) => {
                              return (
                                <option key={c.value} value={c.value}>{c.name}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    </div>
                    {
                      (currentUser.last_package && currentUser.last_package.can_subsidi == true ) ? (
                        <div className='col-6'>
                          <Label className='label-form-group'>Potongan Subsidi</Label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-coins" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Subsidi Belanja"
                              value={subsidi} onChange={e => setSubsidi(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                      ) : null
                    }
                  </>
                ) : null }

                { currentUser && (currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster') ? (
                  <>              
                    {/* <div className='col-6'>
                      <Label className='label-form-group'>Komisi Closing Pertama</Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Closing Pertama"
                          value={byStaffCommision} onChange={e => setByStaffCommision(e.target.value)}
                        />
                      </InputGroup>
                    </div>

                    <div className='col-6'>
                      <Label className='label-form-group'>Komisi Closing RO</Label>
                      <InputGroup className="input-group-alternative">
                        <Input
                          placeholder="Closing RO"
                          value={byStaffCommisionRepeatOrder} onChange={e => setByStaffCommisionRepeatOrder(e.target.value)}
                        />
                      </InputGroup>
                    </div> */}
                  </>
                ) : null }

                {
                  selectOrder.in_outlet === true ? (
                    <>
                      { currentUser && (currentUser.role_access === 'inventory' || currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster' || currentUser.type === 'Merchant') ? (
                        <>
                          <div className='col-12'>
                            <Label className='label-form-group'>Ubah Status {selectOrder.in_outlet === true ? 'Penerimaan' : 'Pengiriman'}</Label>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-truck" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="select" value={statusShipping} onChange={e => setStatusShipping(e.target.value)}>
                                <option value=''>Ubah Status</option>
                                {
                                  statusCollectionShipping.map((c) => {
                                    return (
                                      <option key={c.value} value={c.value}>{c.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </InputGroup>
                          </div>
                        </>
                      ) : null }
                    </>
                  ) : null
                }

                { currentUser && (currentUser.role_access === 'finance' || currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster') ? (
                    <div className='col-sm-12'>
                      <Label className='label-form-group'>Pilih CS Handling</Label>
                      <Input type="select" value={staffId} onChange={e => setStaffId(e.target.value)}>
                        <option value=''>Pilih CS Handling</option>
                        <option value='no_cs'>Tidak Mempunyai Cs</option>
                        {
                          customerServices.map((c) => {
                            return (
                              <option key={c.id} value={c.id}>{c.full_name}</option>
                            )
                          })
                        }
                      </Input>
                    </div>
                ) : null }


              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body" className='box-dashboard'>
          { currentUser && currentUser.role_access && currentUser.role_access === 'cs' ? (
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          OMSET
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_store ? numberFormat(report.order_store) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          SUBSIDI
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.subsidi ? numberFormat(report.subsidi) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          ONGKIR
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_store ? numberFormat((parseInt(report.order_shipping)+parseInt(report.order_shipping_cod))) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          KOMISI
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.staff_commision ? numberFormat(parseInt(report.staff_commision)) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : currentUser && currentUser.type === 'Merchant' ? (
            <Row>
              <Col lg="6" xl="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          OMSET
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_basic ? numberFormat(report.order_basic) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="6">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          ONGKIR
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_store ? numberFormat((parseInt(report.order_shipping)+parseInt(report.order_shipping_cod))) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? (
            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          OMSET
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_store ? numberFormat(report.order_store) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          SUBSIDI
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.subsidi ? numberFormat(report.subsidi) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          ONGKIR
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_store ? numberFormat((parseInt(report.order_shipping)+parseInt(report.order_shipping_cod))) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0 m-b20">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          PESANAN
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_count ? numberFormat(report.order_count) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0 m-b20">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          OMSET PENJUALAN
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_store ? numberFormat(parseInt(report.order_store)-parseInt(report.virtual_spendings)) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0 m-b20">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          PERKIRAAN MODAL
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_basic ? numberFormat(parseInt(report.order_basic)) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0 m-b20">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          SUBSIDI
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.subsidi ? numberFormat(report.subsidi) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0 m-b20">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          MARGIN
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_store ? numberFormat(parseInt(report.order_store)-parseInt(report.order_basic)-parseInt(report.virtual_spendings ? report.virtual_spendings : 0)) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0 m-b20">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          ONGKIR
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_shipping ? numberFormat(parseInt(report.order_shipping)+parseInt(report.order_shipping_cod)) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2'>
                    <div className='form-group'>
                      <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div className='form-group'>
                      <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                    </div>
                  </div>
                  <div className='col m2 s2'>
                    <input type='text' placeholder='Level User' className='form-control' value={level} onChange={e => updateSearchLevel(e.target.value)}/>
                  </div>
                  <div className='col-sm-2'>
                    <Input type="select" value={byStaffId} onChange={e => updateSearchStaffId(e.target.value)}>
                      <option value=''>Pilih CS User</option>
                      <option value='no_cs'>Tidak Mempunyai Cs</option>
                      {
                        customerServices.map((c) => {
                          return (
                            <option key={c.id} value={c.id}>{c.full_name}</option>
                          )
                        })
                      }
                    </Input>
                  </div>
                  <div className='col-sm-2'>
                    <div className='form-group'>
                      <Input type="select" value={searchStatusPayment} onChange={e => statusOrderPaymentSearch(e.target.value)}>
                        <option value=''>Status Pembayaran</option>
                        { statusCollectionPayment.map((s) => {
                          return (
                            <option value={s.value}>{s.name}</option>
                          )
                        })}
                      </Input>
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='form-group'>
                      <Input type="select" value={searchStatusShipping} onChange={e => statusTrackOrderSearch(e.target.value)}>
                        <option value=''>Status Pengiriman</option>
                        { statusCollectionShipping.map((s) => {
                          return (
                            <option value={s.value}>{s.name}</option>
                          )
                        })}
                      </Input>
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='form-group'>
                      <Input type="select" value={searchCategoryDate} onChange={e => updateSearchCategoryDate(e.target.value)}>
                        <option value=''>Kategori Tanggal</option>
                        { categoryDate.map((s) => {
                          return (
                            <option value={s.value}>{s.name}</option>
                          )
                        })}
                      </Input>
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='form-group'>
                      <DatePicker id= "example-datepicker" value={searchStartDate} onChange={e => updateSearchStartDate(e)} />
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='form-group'>
                      <DatePicker id= "example-datepicker2" value={searchEndDate} onChange={e => updateSearchEndDate(e)} />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='tab-nav back-white'>
                      <ul className='margin-none'>
                        { !(currentCompany.can_reservation || currentCompany.is_showroom) ? (
                          <>
                            {navCollection.map((nav, index) => {
                              return (
                                <li key={index+=1} className={nav.value === searchStatusShipping ? 'active' : null } onClick={() => { statusTrackOrderSearch(nav.value)} }>
                                  {nav.name}
                                </li>
                              );
                            })}
                          </>
                        ) : null }
                        
                        { print === false ? (
                          <>
                            <li style={{float: 'right', padding: 0}}>
                              <button className='btn btn-default' style={{fontSize: 10, paddingBottom: 5, paddingTop: 5, background: (currentCompany.company_color3)}} onClick={() => modePrint()}>MODE PRINT</button>
                            </li>
                            <li style={{float: 'right', padding: 0, paddingRight: 5}}>
                              <Button onClick={(e) => exportData(e)} className='btn btn-default full-width' style={{fontSize: 10, paddingBottom: 5, paddingTop: 5, background: '#554e4e'}}><i className="fas fa-download"></i> Export</Button>
                            </li>
                          </>
                        ) : (
                          <>
                            <li style={{float: 'right', padding: 0}}>
                              <button className='btn btn-default' style={{fontSize: 10, paddingBottom: 5, paddingTop: 5, background: (currentCompany.company_color3)}} onClick={() => modePrint()}>MODE PESANAN</button>
                            </li>
                            <li style={{float: 'right', padding: 0}}>
                              <button className='btn btn-default' style={{marginRight: 5, fontSize: 10, paddingBottom: 5, paddingTop: 5, background: (currentCompany.company_color3)}} onClick={() => window.print()}>PRINT HALAMAN</button>
                            </li>
                          </>
                        )}

                      </ul>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <div className="list-orders to-print" style={{padding: 10}}>
                <div className="row">
                  { spinner ? (
                    <center>
                      <div className='loadLoading'><Dots color='#444' /></div>
                    </center>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <>
                              { print === true ? (
                                <div className='col-12'>
                                  <div className='order-print-box box-page-break'>
                                    <div className='order-print-box-header'>
                                      <div className='row'>
                                        <div className='col-4'><img src={currentCompany ? currentCompany.company_icon_url : ''} style={{height: 30}}/></div>
                                        <div className='col-4'>
                                          <div style={{fontSize: 20, fontWeight: 'bold'}}>
                                            {
                                              u.in_outlet === true ? (
                                                'Pemesanan di Tempat'
                                              ) : (
                                                <>
                                                  {u.expedition_name.toUpperCase()} {u.expedition_service}
                                                </>
                                              )
                                            }
                                          </div>
                                        </div>
                                        <div className='col-4'>
                                          <img src={u.expedition ? u.expedition.image_url : ''} style={{height: 30}}/>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='order-print-box-body'>
                                      {u.expedition_number ? (
                                        <div className='order-print-box-code'>
                                          <b>NO.RESI {u.expedition_number ? u.expedition_number : ''}</b>
                                        </div>
                                      ) : (
                                        <div className='order-print-box-code'>
                                          <b>NO.PESANAN {u.code ? u.code : ''}</b>
                                        </div>
                                      ) }
                                      
                                      <div className='row' style={{paddingBottom:10}}>
                                        <div className='col-6' style={{textAlign: 'left'}}>
                                          <b>{u.store_name}</b> <br/>{u.store_number}
                                          <hr style={{marginBottom:10, marginTop:10}}/>
                                          <b>Pemesan : {u.customer_name}</b> <br/>{u.customer_phone}
                                          <br/>
                                          {
                                            u.in_outlet === false ? (
                                              <>
                                                {u.customer_address} {u.customer_district}, {u.customer_city}, {u.customer_province}
                                              </>
                                            ) : (
                                              <><br/>Nomor Table <b>{u.outlet_table}</b><br/></>
                                            )
                                          }
                                        </div>
                                        <div className='col-6' style={{textAlign: 'left'}}>
                                          <center>
                                            <QRCode value={u.code} size='130'/>
                                          </center>
                                        </div>
                                      </div>
                                      {
                                        u.in_outlet === false ? (
                                          <div className='row'>
                                            <div className='col-6'>
                                              <div className='order-print-box-code'>
                                                {u.customer_city}
                                              </div>
                                            </div>
                                            <div className='col-6'>
                                              <div className='order-print-box-code'>
                                                {u.customer_district}
                                              </div>
                                            </div>
                                          </div>
                                        ) : null
                                      }
                                      <div className='row'>
                                        <div className='col-6'>
                                          <div className='order-print-box-code'>
                                            {u.customer_payment_method === 'COD' ? 'Tagihan COD' : 'Pembayaran'}
                                          </div>
                                        </div>
                                        <div className='col-6'>
                                          <div className='order-print-box-code'>
                                            {
                                              u.in_outlet === true ? (
                                                <>
                                                  <b>{currencyFormat((parseInt(u.store_price)+parseInt(u.total_shipping_price)+parseInt(u.uniq_code)-parseInt(u.subsidi)))}</b>
                                                </>
                                              ) : (
                                                <>
                                                  {u.customer_payment_method === 'COD' ? (
                                                    <b>{currencyFormat((parseInt(u.store_price)+parseInt(u.total_shipping_price)+parseInt(u.uniq_code)-parseInt(u.subsidi)))}</b>
                                                  ) : currencyFormat(0)}
                                                </>
                                              )
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-6' style={{textAlign: 'left'}}>
                                          <b>No.Pesanan: {u.code}</b>
                                          {
                                            u.in_outlet === false && !(currentCompany.can_reservation || currentCompany.is_showroom) ? (
                                              <>
                                                <br/>
                                                Berat: {u.expedition_weight}gr
                                              </>
                                            ) : null
                                          }
                                          <br/>
                                          {
                                            u.booking_order.id ? (
                                              <div>
                                                {
                                                  currentCompany.is_hotel ? (
                                                    <span>{u?.booking_order?.booking_days} Hari, {u?.booking_order?.booking_people} Orang</span>
                                                  ) : (
                                                    <span>{u?.booking_order?.booking_people} Orang</span>
                                                  )
                                                } - Reservasi {u?.booking_order?.room?.name} &nbsp;
                                                {
                                                  currentCompany.is_hotel ? (
                                                    <span>{u?.booking_order?.start_date} s/d {u?.booking_order?.end_date} </span>
                                                  ) : (
                                                    <span>{u?.booking_order?.start_date} {u?.booking_order?.booking_time}</span>
                                                  )
                                                }
                                              </div>
                                            ) : null
                                          }
                                          {
                                            u.order_detail ? (
                                              u.order_detail.map((oi) => {
                                                return(
                                                  <>
                                                    <div>
                                                      {oi.quantity} Qty - {oi.product_name}
                                                    </div>
                                                    <div>
                                                      { oi.order_item_codes ? (
                                                          oi.order_item_codes.map((oc) => {
                                                            return (
                                                              <label className='label label-default' style={{marginRight: 2, marginTop: 5}}>
                                                                {oc.code}
                                                              </label>
                                                            )
                                                          })
                                                        ) : null
                                                      }
                                                    </div>
                                                  </>
                                                )
                                              })
                                            ) : null
                                          }
                                        </div>
                                        <div className='col-6' style={{textAlign: 'left'}}>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className='list-order' key={u.id}>
                                  <div className='row'>
                                    <div className='col-md-3'>
                                      <div className='form-group'>
                                        <Link to={'order/'+u.id} className='list-order-code'>
                                          #<b>{u.code}</b> 
                                        </Link>
                                        <br/>Dibuat {u.order_date} 
                                        {
                                          u.today_order ? (
                                            <label className='label label-red' style={{marginLeft:5}}>Hari Ini</label>
                                          ) : null
                                        }
                                        <label className='label' style={{marginBottom:10, marginLeft:5}}>
                                          {u.dropship && u.mitra ? `Dropship ${u.category_status}` :  u.mitra === false ? `${u.category_status}` : `${u.category_status}`}
                                        </label> 
                                        { u?.booking_order.id ? (
                                          <li className='li-order-item'>  
                                            <span className='list-order-name'>Reservasi {u?.booking_order?.room?.name} - {currencyFormat(u?.booking_order?.booking_price)}</span>
                                            <br/>
                                            {
                                              currentCompany.is_hotel ? (
                                                <div className='card-variant'>
                                                  <span>{u?.booking_order?.start_date} s/d {u?.booking_order?.end_date} </span>
                                                  <br/>
                                                  <span>{u?.booking_order?.booking_days} Hari, {u?.booking_order?.booking_people} Orang</span>
                                                </div>
                                              ) : (
                                                <div className='card-variant'>
                                                  <span>{u?.booking_order?.start_date} {u?.booking_order?.booking_time}</span>
                                                  <br/>
                                                  <span>{u?.booking_order?.booking_people} Orang</span>
                                                </div>
                                              )
                                            }
                                          </li>
                                        ) : null }
                                        {
                                          u.order_detail ? (
                                            u.order_detail.map((oi) => {
                                              return(
                                                <li className='li-order-item'>
                                                
                                                  { oi.discount > 0 ? (
                                                    <span className='list-order-name'>
                                                      {oi.product_name} ({oi.quantity} pcs x <span className='discount-price'>{currencyFormat(parseInt(oi.store_price)+(parseInt(oi.discount)/parseInt(oi.quantity)))}</span>&nbsp; {numberFormat(oi.store_price)})
                                                    </span>
                                                  ) : (
                                                    <span className='list-order-name'>
                                                      {oi.product_name} ({oi.quantity} pcs x {numberFormat(oi.store_price)})
                                                    </span>
                                                  ) }

                                                  <br/>
                                                  { oi.variant_name_1 ? (
                                                    <>
                                                      Pilihan Varian: {oi.variant_name_1}, {oi.variant_name_2}
                                                    </>
                                                  ) : null }

                                                  <div>
                                                    { 
                                                      oi.order_item_codes.map((oc) => {
                                                        return (
                                                          <label className='label label-default' style={{marginRight: 2, marginTop: 5}}>
                                                            {oc.code}
                                                          </label>
                                                        )
                                                      })
                                                    }
                                                  </div>
                                                </li>
                                              )
                                            })
                                          ) : null
                                        }
                                        <br/>
                                        { u.voucher_code ? (
                                          <>
                                            REDEEM <b>{u.voucher_code}</b>
                                          </>
                                        ) : null }
                                        <br/>
                                        { u.staff_name ? (
                                          <>
                                            CS <b>{u.staff_name}</b>
                                          </>
                                        ) : null }
                                        <br/>
                                        { u.staff_commision > 0 && currentUser.type !== 'Merchant' ? (
                                          <>
                                            Komisi Closing CS <b>{numberFormat(u.staff_commision)}</b>
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                className="btn-icon-only text-light"
                                                href="#pablo"
                                                role="button"
                                                size="sm"
                                                color=""
                                                onClick={(e) => e.preventDefault()}
                                              >
                                                <i className="fas fa-ellipsis-v" />
                                              </DropdownToggle>
                                              <DropdownMenu className="dropdown-menu-arrow" right>
                                                <DropdownItem onClick={(e) => toggle(u.id)}>
                                                  Ubah
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </>
                                        ) : null }
                                        <br/>
                                        { u.staff_commision_repeat_order > 0 && currentUser.type !== 'Merchant' ? (
                                          <>
                                            Komisi RO CS <b>{numberFormat(u.staff_commision_repeat_order)}</b>
                                            <UncontrolledDropdown>
                                              <DropdownToggle
                                                className="btn-icon-only text-light"
                                                href="#pablo"
                                                role="button"
                                                size="sm"
                                                color=""
                                                onClick={(e) => e.preventDefault()}
                                              >
                                                <i className="fas fa-ellipsis-v" />
                                              </DropdownToggle>
                                              <DropdownMenu className="dropdown-menu-arrow" right>
                                                <DropdownItem onClick={(e) => toggle(u.id)}>
                                                  Ubah
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </>
                                        ) : null }
                                      </div>
                                    </div>
                                    <div className='col-md-3'>
                                      <div className='form-group'>
                                        <b>Pemesan</b>
                                        <br/>
                                        <div>
                                          {u.customer_name} ({u.customer_phone} )
                                          {
                                            u.in_outlet === true ? (
                                              <><br/>Nomor Table <b>{u.outlet_table}</b><br/></>
                                            ) : null
                                          }
                                          <br/>
                                          {
                                            u.in_outlet === false && u.customer_address ? (
                                              <>
                                                <i className='fas fa-map-marker'></i> {u.customer_address}, {u.customer_district}, {u.customer_city}, {u.customer_province}
                                              </>
                                            ) : null
                                          }
                                        </div>
                                        <br/>
                                        { !(currentCompany.can_reservation || currentCompany.is_showroom) ? (
                                            <>
                                              <b>{u.in_outlet === true ? 'Pesan di Tempat' : 'Pengirim'}</b>
                                              <br/>
                                            </>
                                          ) : null
                                        }
                                        <div>
                                          <i className='fas fa-building'></i>  {u.store_name} ({u.store_number})
                                          {
                                            u.store_address ? (
                                              <>
                                                <br/>
                                                <i className='fas fa-map-marker'></i> {u.store_address}
                                              </>
                                            ) : null
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    
                                    <div className='col-md-2'>
                                      {
                                        u.in_outlet === false ? (
                                          <div className='form-group'>
                                            {
                                              u.order_detail.length > 0 ? (
                                                <>
                                                  <b>Pengiriman {u.customer_payment_method === 'COD' ? 'COD' : null}</b>
                                                  <br/>
                                                  Status: <label style={styles.status(u.status_shipping)}>{u.status_shipping_txt}</label>
                                                  <UncontrolledDropdown>
                                                    <DropdownToggle
                                                      className="btn-icon-only text-light"
                                                      href="#pablo"
                                                      role="button"
                                                      size="sm"
                                                      color=""
                                                      onClick={(e) => e.preventDefault()}
                                                    >
                                                      <i className="fas fa-ellipsis-v" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                      <DropdownItem onClick={(e) => toggle(u.id)}>
                                                        Ubah
                                                      </DropdownItem>
                                                    </DropdownMenu>
                                                  </UncontrolledDropdown>
                                                  {
                                                    u.send_date ? (
                                                      <>
                                                        <br/>
                                                        Dikirim {u.send_date}
                                                      </>
                                                    ) : null
                                                  }
                                                  {
                                                    u.received_date ? (
                                                      <>
                                                        <br/>
                                                        Diterima {u.received_date}
                                                      </>
                                                    ) : null
                                                  }
                                                  <br/>
                                                </>
                                              ) : null
                                            }
                                            
                                            {
                                              u.order_detail.length > 0 ? (
                                                <>
                                                  <i className='fas fa-truck'></i> {u.expedition_name.toUpperCase()} - {u.expedition_service}
                                                  <br/>
                                                  { !(currentCompany.can_reservation || currentCompany.is_showroom) ? (
                                                    <>
                                                      Berat : {u.expedition_weight} gram
                                                      <br/>
                                                      Nomor Resi : {u.expedition_number && u.expedition_number.length > 4 ? u.expedition_number : 'Belum Ada'}
                                                      {
                                                        u.fee_cod > 0 ? (
                                                          <>
                                                            <br/>
                                                            FEE COD : {currencyFormat(u.fee_cod)}
                                                          </>
                                                        ) : null
                                                      }
                                                      <br/>
                                                    </>
                                                  ) : null }
                                                  Total Ongkir: {currencyFormat(u.shipping_price)}
                                                </>
                                              ) : null }

                                            { u.dropship && u.user.group_user_name !== null ? (
                                              <>
                                                <br/>
                                                <br/>
                                                <b>Tagihan Pelanggan</b>
                                                <table className='table-order'>
                                                  <tr>
                                                    <td>
                                                      Total Barang
                                                    </td>
                                                    <td>
                                                      : {currencyFormat(u.sell_price)}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      Total Ongkir
                                                    </td>
                                                    <td>
                                                      : {currencyFormat(u.total_shipping_price)}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      Total {u.customer_payment_method}
                                                    </td>
                                                    <td>
                                                      : <b>{currencyFormat(parseInt(u.sell_price)+parseInt(u.total_shipping_price))}</b>
                                                    </td>
                                                  </tr>
                                                </table>
                                              </>
                                            ) : null}
                                          </div>
                                        ) : null
                                      }
                                    </div>
                                    
                                    <div className='col-md-4'>
                                      <div className='form-group'>
                                        <b>Transaksi</b>
                                        <table className='table-order'>
                                          <tr>
                                            <td>
                                              Status Pembayaran
                                            </td>
                                            <td>
                                              : <label style={styles.status_payment(u.status_payment)}>{u.status_payment_txt}</label>
                                              <UncontrolledDropdown>
                                                <DropdownToggle
                                                  className="btn-icon-only text-light"
                                                  href="#pablo"
                                                  role="button"
                                                  size="sm"
                                                  color=""
                                                  onClick={(e) => e.preventDefault()}
                                                >
                                                  <i className="fas fa-ellipsis-v" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                  <DropdownItem onClick={(e) => toggle(u.id)}>
                                                    Ubah
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Nama Pemesan
                                            </td>
                                            <td>
                                              : {u.user.full_name}
                                            </td>
                                          </tr>
                                          {
                                            u.paid_date ? (
                                              <tr>
                                                <td>
                                                  Dibayar
                                                </td>
                                                <td>
                                                  : {u.paid_date}
                                                </td>
                                              </tr>
                                            ) : null
                                          }
                                          <tr>
                                            <td>
                                              Subtotal
                                            </td>
                                            <td>
                                              : {currencyFormat(u.store_price)}
                                            </td>
                                          </tr>
                                          {
                                            u.in_outlet === false && u.total_shipping_price > 0 ? (
                                              <tr>
                                                <td>
                                                  Total Ongkir
                                                </td>
                                                <td>
                                                  : {currencyFormat(u.total_shipping_price)}
                                                </td>
                                              </tr>
                                            ) : null
                                          }
                                          {
                                            u.uniq_code > 0 ? (
                                              <tr>
                                                <td>
                                                  Kode Unik
                                                </td>
                                                <td>
                                                  : {currencyFormat(u.uniq_code)}
                                                </td>
                                              </tr>
                                            ) : null
                                          }
                                          {
                                            u.subsidi > 0 ? (
                                              <tr>
                                                <td>
                                                  Potongan Subsidi
                                                </td>
                                                <td>
                                                  : - {currencyFormat(u.subsidi)}
                                                </td>
                                              </tr>
                                            ) : null
                                          }
                                          { u.virtual_money > 0 ? (
                                            <tr>
                                              <td>
                                                Bayar Pakai Saldo
                                              </td>
                                              <td>
                                                : <b>- {currencyFormat(u.virtual_money)}</b>
                                              </td>
                                            </tr>
                                          ) : null}
                                          <tr>
                                            <td>
                                              Total Pembayaran Anda
                                            </td>
                                            <td>
                                              : <b>{currencyFormat((parseInt(u.store_price)+parseInt(u.total_shipping_price)+parseInt(u.uniq_code)-parseInt(u.subsidi)-parseInt(u.virtual_money)))}</b>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Metode Pembayaran
                                            </td>
                                            <td>
                                              : {u.payment_method}
                                            </td>
                                          </tr>

                                          { u.confirmation_transfer ? (
                                            <tr>
                                              <td>
                                                <label className='label label-default'>Sudah Upload Bukti Pembayaran</label>
                                              </td>
                                              <td>
                                              </td>
                                            </tr>
                                          ) : null
                                          }
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='list-order'>
                      <div className='row'>
                        <div className='col col-md-12 input-field2'>
                          <span>Data Tidak Ditemukan</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {paging ? (
                <div className='col'>
                  <div onClick={loadMoreData} className='load-more-btn'>
                    {loadMore ? (
                      <center><Dots color='#444' /><br/></center>
                    ) : null}
                    SELANJUTNYA
                  </div>
                </div>
              ) : null}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OrderIndex;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : (status === 'shipped' || status === 'received') ? 'green' : status === 'packing' ? '#11cdef' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  }),
  status_payment: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}