import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, useParams, Link } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";
import CreatableSelect from 'react-select/creatable';

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label,
  Col, CardBody, CardTitle
} from "reactstrap";

import Header from "components/Headers/Header.js";

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function currencyFormat(num) {
  let nu = num ? num : 0
  return 'Rp' + parseFloat(nu).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const OrderShow = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();
  const {id} = useParams();

  const [data, setData] = useState([]);
  const [activities, setActivities] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const [statusShipping, setStatusShipping] = useState('');
  const [statusPayment, setStatusPayment] = useState('');
  const [expeditionNumber, setExpeditionNumber] = useState('');
  const [subsidi, setSubsidi] = useState(0);
  const [expeditionName, setExpeditionName] = useState('');
  const [expeditionService, setExpeditionService] = useState('');
  const [shippingPrice, setShippingPrice] = useState('');
  const [selectOrder, setSelectOrder] = useState({});
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [paidDate, setPaidDate] = useState('');

  const [orderItem, setOrderItem] = useState({});
  const [showBoxCart, setShowBoxCart] = useState(false);
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [variant1, setVariant1] = useState('');
  const [variant2, setVariant2] = useState('');
  const [variant1Obj, setVariant1Obj] = useState({});
  const [variant2Obj, setVariant2Obj] = useState({});
  const [productPrice, setProductPrice] = useState(0);
  const [productPriceSell, setProductPriceSell] = useState(0);
  const [variants2, setVariants2] = useState([]);
  const [haveVariant2, setHaveVariant2] = useState(false);
  const [storePrice, setStorePrice] = useState(0);
  const [toAccountId, setToAccountId] = useState('');
  const [staffId, setStaffId] = useState('');
  const [spinnerModal, setSpinnerModal] = useState(false);

  const [orderItemId, setOrderItemId] = useState('');
  const [orderItemArr, setOrderItemArr] = useState([]);
  const [productCodes, setProductCodes] = useState([]);
  const [paymentNumbers, setPaymentNumbers] = useState([]);
  const [customerServices, setCustomerServices] = useState([]);
  const [confirmations, setConfirmations] = useState([]);

  const [orderItems, setOrderItems] = useState([]);
  const [member, setMember] = useState({});
  const [modalItem, setModalItem] = useState(false);
  const toggleItem = (id) => {
    setShowBoxCart(false);
    if(showBoxCart === true){
      setOrderItem({});
      setProduct({});
      setModalItem(false);
    }else{
      modalEditCart(id);
    }
  }

  const onHideCart = () => {
    setModalItem(!modalItem);
    setOrderItem({});
    setProduct({});
  }

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [visibleItem, setVisibleItem] = useState(false);
  const onDismissItem = () => setVisibleItem(false);
  const [errorMessages, setErrorMessages] = useState('');

  const statusCollectionShipping = [
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Dikemas', 'value':'packing'},
    {'name':'Dikirim', 'value':'shipped'},
    {'name':'Diterima', 'value':'received'},
    {'name':'Dibatalkan', 'value':'cancelled'},
    {'name':'Diretur', 'value':'retur'}
  ]

  const statusCollectionPayment = [
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Dibayar', 'value':'paid'},
    {'name':'Dibatalkan', 'value':'cancelled'}
  ]

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const loadData = () => {
    let config = { headers: { Authorization: `Bearer ${token}`}, params: {key: '', include: 'order_items, order_detail, booking_order, room, user, activities, order_item_codes, account_to, confirmations' }};
    API.get(`orders/${id}`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setData(data);
        setOrderItems(data.order_items);
        setStatusShipping(data.status_shipping);
        setStatusPayment(data.status_payment);
        setExpeditionNumber(data.expedition_number);
        setSubsidi(data.subsidi);
        setExpeditionName(data.expedition_name);
        setExpeditionService(data.expedition_service);
        setShippingPrice(data.shipping_price);
        setActivities(data.activities);
        setImageSrc(data.image_url);
        setImageFile('');
        setPaidDate(data.paid_date);
        setSpinner(false);
        setMember(data.user);
        setToAccountId(data.to_account_id);
        setStaffId(data.staff_id);
        setConfirmations(data.confirmations);

        config = { headers: { Authorization: `Bearer ${token}`}, params: {by_use_payment: true, by_company_id: data.company_id, by_status: true, pagination: false }};
        API.get(`accounts`, config)
          .then(res => {
            const data = res.data.data;
            console.log(res.data)
            setPaymentNumbers(data);
          })
      })
  }

  const actPost = () => {
    setSpinnerSave(true);
    const config = { headers: { Authorization: `Bearer ${token}` } };

    const fd = new FormData();
    fd.append('status_shipping', statusShipping);
    fd.append('status_payment', statusPayment);
    fd.append('expedition_number', expeditionNumber);
    fd.append('subsidi', subsidi);
    fd.append('expedition_name', expeditionName);
    fd.append('expedition_service', expeditionService);
    fd.append('shipping_price', shippingPrice);
    fd.append('to_account_id', toAccountId);
    fd.append('staff_id', staffId);
    if(imageFile){
      fd.append('image_transfer', imageFile, imageFile.name);
    }
    fd.append('paid_date', paidDate);
    fd.append('include', 'order_items, order_detail, booking_order, room, user, activities, order_item_codes, account_to, confirmations');

    if(id){
      API.put(`orders/${id}`, fd, config )
        .then(res => {
          const data = res.data.data;
          setStatusShipping(data.status_shipping);
          setStatusPayment(data.status_payment);
          setExpeditionNumber(data.expedition_number);
          setSubsidi(data.subsidi);
          setExpeditionName(data.expedition_name);
          setExpeditionService(data.expedition_service);
          setShippingPrice(data.shipping_price);
          setToAccountId(data.to_account_id);
          setStaffId(data.staff_id);
          setImageSrc(data.image_url);
          setImageFile('');
          setPaidDate(data.paid_date);
          loadData();
          setSpinnerSave(false);
          alert('Data berhasil di ubah');
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
    
  }


  const IncrementItemModal = () => {
    let qty = (quantity+1);
    setQuantity(qty)
  }

  const DecreaseItemModal = () => {
    let qty = quantity-1
    if(qty <= 1){
      setQuantity(1)
    }else{
      setQuantity((quantity-1))
    }
  }

  const deleteItem = (oi) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    
    API.delete(`order_items/${oi}?call_order=true&include=order_items,product`, config)
      .then(res => {
        const data = res.data.data;
        setOrderItems(data.order_items);
        setData(data);
      }).catch(error => {
        if(error === undefined){
          alert('Gagal, Telah terjadi kesalahan server');
        }else{
          alert(`Gagal ${error.response.data.meta.status.message}`);
        }
      })
  }

  const modalEditCart = (id) => {
    setShowBoxCart(true);
    let order_item = orderItems.filter(o => o.id === id )[0]
    setOrderItem(order_item);
    setStorePrice(order_item.store_price);
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {include: 'user, product_variants, product_variant_prices, product_prices'} };

    API.get(`products/${order_item.product_id}`, config)
      .then(res => {
        setSpinnerModal(false);
        const data = res.data.data;
        setProduct(data);
        setQuantity(order_item.quantity);
        setProductPrice(data.sell_price);
        
        if(data.product_variants && data.product_variants.filter(o => o.parent_id === null).length > 0){
          let varian_sku = data.product_variants.filter(o => o.parent_id === null && o.sku === order_item.variant_sku_1)[0]

          if(varian_sku){
            let opts = { headers: { Authorization: `Bearer ${token}`}, params: {by_sku: varian_sku.sku} }
            setVariant1(varian_sku.sku);
            setVariant1Obj(varian_sku)

            if(data.product_type == 'price_on_variant' && data.product_prices.length > 0){
              let pPrice = data.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === varian_sku.id )[0]
              if(pPrice !== undefined){
                setProductPrice(pPrice.sell_price);
                setProductPriceSell(varian_sku.sell_price);
              }else{
                setProductPrice(varian_sku.sell_price);
                setProductPriceSell(varian_sku.sell_price);
              }
            }else{
              let pPrice = data.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === null )[0]
              if(pPrice !== undefined){
                setProductPrice(pPrice.sell_price);
              }else{
                setProductPrice(data.sell_price);
              }
              setProductPriceSell(product.sell_price);
            }
            
            API.get(`product_variants`, opts)
              .then(res => {
                const data = res.data.data;
                if(data && data.length > 0){
                  setVariant2(order_item.variant_sku_2);
                  setVariant2Obj(data.filter(o => o.sku === order_item.variant_sku_2)[0])
                  setHaveVariant2(true)
                  setVariants2(data);
                }else{
                  setVariant2('');
                  setVariant2Obj({})
                  setHaveVariant2(false)
                  setVariants2([]);
                }
              })
          }
        }else{
          let pPrice = data.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === null)[0]
          if(pPrice !== undefined){
            setProductPrice(pPrice.sell_price);
          }else{
            setProductPrice(data.sell_price);
          }
          setProductPriceSell(data.sell_price);
        }

        setModalItem(true);
      })
    
  }

  const loadVariant = (sku) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {sku: sku, include: 'product_variant_prices'} };
    setVariant1(sku);

    API.get(`product_variant_sku`, config)
      .then(res => {
        const data = res.data.data;
        setVariant1Obj(data);
        let pPrice = product.sell_price;
        if(product.product_prices.length > 0){
          if(product.product_type == 'price_on_variant'){
            pPrice = product.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === data.id )[0]
            if(pPrice !== undefined){
              setProductPrice(pPrice.sell_price);
              setProductPriceSell(data.sell_price);
            }else{
              setProductPrice(data.sell_price);
              setProductPriceSell(data.sell_price);
            }
          }else{
            pPrice = product.product_prices.filter(o => parseInt(o.group_user_id) === parseInt(member ? member.group_user_id : null) && o.product_variant_id === null )[0]
            if(pPrice !== undefined){
              setProductPrice(pPrice.sell_price);
            }else{
              setProductPrice(product.sell_price);
            }
            setProductPriceSell(product.sell_price);
          }
        }else{
          setProductPrice(product.sell_price);
          setProductPriceSell(product.sell_price);
        }
      })
    
    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_sku: sku} };
    API.get(`product_variants`, config2)
      .then(res => {
        const data = res.data.data;
        if(data && data.length > 0){
          setVariant2(data[0].sku);
          setVariant2Obj(data[0])
          setHaveVariant2(true)
          setVariants2(data);
        }else{
          setVariant2('');
          setVariant2Obj({})
          setHaveVariant2(false)
          setVariants2([]);
        }
      })
  }

  const setLoadVariant2 = (sku) => {
    const config = { headers: { Authorization: `Bearer ${token}`}, params: {sku: sku} };
    setVariant2(sku);

    API.get(`product_variant_sku`, config)
      .then(res => {
        const data = res.data.data;
        setVariant2Obj(data)
      })
  }
  
  const editCart = (id) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const paramsData = { 
      store_price: storePrice, variant_sku_1: variant1, variant_sku_2: variant2, quantity: quantity, call_order: true, include: 'order_items, order_detail, booking_order, room, product, user, activities, order_item_codes'
    };
    
    API.put(`order_items/${id}`, paramsData, config)
      .then(res => {
        const data = res.data.data;
        setOrderItems(data.order_items);
        setData(data);
        setModalItem(!modalItem);
      }).catch(error => {
        if(error === undefined){
          setErrorMessages('Gagal, Telah terjadi kesalahan server');
        }else{
          setErrorMessages(`Gagal ${error.response.data.meta.status.message}`);
        }
      })
  }

  const updateCode = (arr) => {
    setOrderItemId(orderItemId)
    setOrderItemArr(arr)
  }

  const setOrderToUpdate = (oi_id, arr) => {
    setOrderItemId(oi_id)
    setOrderItemArr(arr)

    const config = { headers: { Authorization: `Bearer ${token}`} };
    API.get(`order_items/${oi_id}/get_codes`, config)
      .then(res => {
        const data = res.data.data;
        setProductCodes(data)
      })

  }

  const editUpdateOrderCode = (id) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const paramsData = { 
      order_item_codes: orderItemArr, include: 'order_items, order_detail, booking_order, room, product, user, activities, order_item_codes', call_order: true
    };
    
    API.put(`order_items/${id}/update_code`, paramsData, config)
      .then(res => {
        const data = res.data.data;
        setOrderItems(data.order_items);
        setData(data);
        setOrderItemId('');
        setOrderItemArr([]);
        setProductCodes([]);
        alert('Sukses Berhasil, Diubah!');
      }).catch(error => {
        if(error === undefined){
          alert('Gagal, Telah terjadi kesalahan server');
        }else{
          alert(`Gagal ${error.response.data.meta.status.message}`);
        }
      })
  }

  const setCompanyLoad = (company_id) => {
    const config2 = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, by_company_id: company_id, by_user_type: 'Staff', by_role_access: 'cs', pagination: false }};
    API.get(`users`, config2)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCustomerServices(data);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }
  
  const getNotePaymentLink = (code) => {
    return apiBaseUrl+'/orders/'+ code +'.pdf';
  }

  const getNotePaymentLinkMini = (code) => {
    return apiBaseUrl+'/orders/'+ code +'.pdf?cashier=true';
  }

  const changeVerified = (id) => {
    const config = { 
      headers: { Authorization: `Bearer ${token}` } 
    };
    
    API.put(`confirmations/${id}/verified`, {status: ''}, config )
      .then(res => {
        const data = res.data.data;
        alert(`Berhasil di ${data.verified ? 'Aktifkan' : 'NonAktif'}`);
        loadData('');
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })
  }

  const printContent = () => {
    const content = document.getElementById('printable-content').innerHTML;
    const printWindow = window.open('', '', 'width=600,height=400');
    printWindow.document.write(`
      <html>
      <head>
        <title>Print</title>
        <style>
            body {
              font-family: Arial, sans-serif;
              width: 300px;
              font-size: 12px;
            }
            #printable-content {
              display: block; /* Ensure it's visible */
            }
            table, td, th {
              border: 1px solid;
            }

            table {
              font-size: 11px;
              width: 100%;
              border-collapse: collapse;
            }
            table td{
              padding: 4px;
            }
            @media print {
              button {
                  display: none; /* Hide button during print */
              }
              @page {
                size: 86mm 100mm;
                margin: 0;
              }
            }
        </style>
      </head>
      <body>
          <div>${content}</div>
      </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close(); // Close the print window after printing
  };
  
  useEffect(() => {
    loadData('');

    if(currentUser && currentUser.type === 'Company'){
      setCompanyLoad(currentUser.id);
    }else if (currentUser && currentUser.type === 'Staff'){
      setCompanyLoad(currentUser.company_id)
    }

    if(token === undefined){
      history.push("/")
    }
  },[])

  return (
    <>
      <div id="printable-content">
        {
          currentCompany ? (
            <center>
              <img alt="..." src={currentCompany?.company_icon_url} style={{width: 120, marginBottom: 10}}/>
              <br/>
              <div><b>{currentCompany.full_name}</b></div>
              <div>{currentCompany.place_info} . Telepon: {currentCompany.phone1}</div>
              <hr/>
              <b>NOTA #{data.code}</b>
              <div>{data.order_date} An. {data.customer_name}</div>
              <br/>
              <table style={{width: '100%;'}}>
                <tbody>
                  {
                    data.booking_order && data.booking_order.room ? (
                      <tr>
                        <td>
                          Reservasi {data?.booking_order?.room?.name}
                          <br/>
                          {
                            currentCompany.is_hotel ? (
                              <div>{data?.booking_order?.start_date} s/d {data?.booking_order?.end_date} </div>
                            ) : (
                              <div>{data?.booking_order?.start_date} {data?.booking_order?.booking_time}</div>
                            )
                          }
                          {
                            currentCompany.is_hotel ? (
                              <div>
                                <span>{data?.booking_order?.booking_days} Hari</span>
                              </div>
                            ) : (
                              <div>
                                <span>{data?.booking_order?.booking_people} Orang</span>
                              </div>
                            )
                          }         
                        </td>
                        <td style={{textAlign: 'right'}}>{numberFormat(data?.booking_order?.booking_price)}</td>
                      </tr>
                    ) : null
                  }                              

                  {
                    data.order_detail ? (
                      data.order_detail.map((oi) => {
                        return(
                          <tr>
                            <td>
                              <div>
                                {oi.product_name}
                              </div>
                              <div>
                                <span>
                                  { oi.variant_name_1 ? (
                                    <>
                                      {oi.variant_name_1} {oi.variant_name_2}
                                    </>
                                  ) : null }
                                </span>
                              </div>
                              <div>
                                {oi.quantity} x {numberFormat(oi.store_price)}
                              </div>
                            </td>
                            <td style={{textAlign: 'right'}}>
                              {numberFormat(oi.store_price*oi.quantity)}
                            </td>
                          </tr>
                        )
                      })
                    ) : null
                  }

                  {
                    data.subsidi > 0 ? (
                      <tr>
                        <td>SUBSIDI</td>
                        <td style={{textAlign:'right'}}>
                          <span>- {numberFormat(data.subsidi)}</span>
                        </td>
                      </tr>
                    ) : null
                  }

                  {
                    data.total_shipping_price > 0 ? (
                      <tr>
                        <td>ONGKOS KIRIM</td>
                        <td style={{textAlign:'right'}}>
                          <span>{numberFormat(data.total_shipping_price)}</span>
                        </td>
                      </tr>
                    ) : null
                  }

                  {
                    data.uniq_code > 0 ? (
                      <tr>
                        <td>KODE UNIK</td>
                        <td style={{textAlign:'right'}}>
                          <span>{numberFormat(data.uniq_code)}</span>
                        </td>
                      </tr>
                    ) : null
                  }

                  {
                    data.virtual_money > 0 ? (
                      <tr>
                        <td>BAYAR SALDO</td>
                        <td style={{textAlign:'right'}}>
                          <span>- {numberFormat(data.virtual_money)}</span>
                        </td>
                      </tr>
                    ) : null
                  }

                  <tr>
                    <td>TOTAL</td>
                    <td style={{textAlign:'right'}}>
                      <b>{numberFormat((parseInt(data.store_price)+parseInt(data.total_shipping_price)+parseInt(data.uniq_code)-parseInt(data.subsidi)-parseInt(data.virtual_money)))}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              <br/>
              <p><span>Terimakasih sudah berbelanja produk kami</span></p>
            </center>
          ) : null
        }
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>PESANAN {data.code}</ModalHeader>
        <ModalBody className='pt-0'>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                {
                  data.in_outlet === false ? (
                    <>
                      { currentUser && (currentUser.role_access === 'inventory' || currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster' || currentUser.type === 'Merchant') ? (
                        <>
                          <div class='col-6'>
                            <Label className='label-form-group'>Ubah Status Pengiriman</Label>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-truck" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="select" value={statusShipping} onChange={e => setStatusShipping(e.target.value)}>
                                <option value=''>Ubah Status Pengiriman</option>
                                {
                                  statusCollectionShipping.map((c) => {
                                    return (
                                      <option key={c.value} value={c.value}>{c.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </InputGroup>
                          </div>
                          <div class='col-6'>
                            <Label className='label-form-group'>Nomor Resi</Label>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-truck" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Nomor Resi"
                                value={expeditionNumber} onChange={e => setExpeditionNumber(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                          <div class='col-6'>
                            <Label className='label-form-group'>Expedisi Pengiriman</Label>
                            <InputGroup className="input-group-alternative">
                              <Input
                                placeholder="Nama Expedisi"
                                value={expeditionName} onChange={e => setExpeditionName(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                          <div class='col-6'>
                            <Label className='label-form-group'>Name Service Expedisi</Label>
                            <InputGroup className="input-group-alternative">
                              <Input
                                placeholder="Nama Service"
                                value={expeditionService} onChange={e => setExpeditionService(e.target.value)}
                              />
                            </InputGroup>
                          </div>
                          { currentUser && currentUser.type !== 'Merchant' ? (
                            <div class='col-12'>
                              <Label className='label-form-group'>Ongkos Kirim</Label>
                              <InputGroup className="input-group-alternative">
                                <Input
                                  placeholder="Nominal Ongkir"
                                  value={shippingPrice} onChange={e => setShippingPrice(e.target.value)}
                                />
                              </InputGroup>
                            </div>
                          ) : null }
                        </>
                      ) : null }
                    </>
                  ) : null
                }

                { currentUser && currentUser.type !== 'Merchant' ? (
                  <>
                    <div class='col-12'>
                      <center>
                        <br/>
                        <div>Upload Bukti Pembayaran</div>
                        <br/>
                        <FormGroup className="form-img-select">
                          <div class='box-image-select'>
                            <ImageUpload
                              handleImageSelect={handleImageSelect}
                              imageSrc={imageSrc}
                              setImageSrc={setImageSrc}
                              style={{
                                width: 120,
                                height: 120
                              }}
                            />
                          </div>
                        </FormGroup>
                      </center>
                    </div>
                    <div className='col-6'>
                      <FormGroup>
                        <Label className='label-form-group'>Tanggal Pembayaran</Label>
                        <DatePicker options={{ yearRange: 70}} id= "example-datepicker3" value={paidDate} onChange={e => setPaidDate(e)} />
                      </FormGroup>
                    </div>
                    <div className='col-6'>
                      <Label className='label-form-group'>Akun Penerimaan</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <Input type="select" value={toAccountId} onChange={e => setToAccountId(e.target.value)}>
                          <option value=''>Pilih No.Rekening</option>
                          {
                            paymentNumbers.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.title+'-'+c.code}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    </div>
                  </>
                ) : null }

                { currentUser && (currentUser.role_access === 'finance' || currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster') ? (
                  <>
                    <div class='col-6'>
                      <Label className='label-form-group'>Ubah Status Pembayaran</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-coins" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" value={statusPayment} onChange={e => setStatusPayment(e.target.value)}>
                          <option value=''>Ubah Status Pembayaran</option>
                          {
                            statusCollectionPayment.map((c) => {
                              return (
                                <option key={c.value} value={c.value}>{c.name}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    </div>
                    <div class='col-6'>
                      <Label className='label-form-group'>Potongan Subsidi</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-coins" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Subsidi Belanja"
                          value={subsidi} onChange={e => setSubsidi(e.target.value)}
                        />
                      </InputGroup>
                    </div>
                  </>
                ) : null }

                {
                  data.in_outlet === true ? (
                    <>
                      { currentUser && (currentUser.role_access === 'inventory' || currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster' || currentUser.type === 'Merchant') ? (
                        <>
                          <div class='col-12'>
                            <Label className='label-form-group'>Ubah Status {data.in_outlet === true ? 'Penerimaan' : 'Pengiriman'}</Label>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-truck" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="select" value={statusShipping} onChange={e => setStatusShipping(e.target.value)}>
                                <option value=''>Ubah Status</option>
                                {
                                  statusCollectionShipping.map((c) => {
                                    return (
                                      <option key={c.value} value={c.value}>{c.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </InputGroup>
                          </div>
                        </>
                      ) : null}
                    </>
                  ) : null 
                }

                { currentUser && (currentUser.role_access === 'finance' || currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster') ? (
                  <div className='col-12'>
                    <Label className='label-form-group'>Pilih CS Handling</Label>
                    <InputGroup className="input-group-alternative">
                      <Input type="select" value={staffId} onChange={e => setStaffId(e.target.value)}>
                        <option value=''>Pilih CS Handling</option>
                        <option value='no_cs'>Tidak Mempunyai Cs</option>
                        {
                          customerServices.map((c) => {
                            return (
                              <option key={c.id} value={c.id}>{c.full_name}</option>
                            )
                          })
                        }
                      </Input>
                      </InputGroup>
                  </div>
                ) : null }
              </div>
          
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalItem} toggle={toggleItem}>
        <ModalBody style={{marginTop: 20}}>
          <Alert color="warning" isOpen={visible} toggle={onDismissItem}>
            {errorMessage}
          </Alert>
          <FormGroup className="mb-3">
            <div class="row widget-box-chart">
              <div class="col-md-12 mb-20">
                <div class="align-items-center media">
                  <a class="avatar rounded-circle mr-3" style={{width:80}}>
                    <img src={product.image_url}/>
                  </a>
                  <div class="media">
                    <span class="mb-0 text-sm">
                      <h4>{product.title}</h4>
                      { variant1Obj.variant_name ? (
                        <div class='card-variant'>Pilihan Varian: {variant1Obj.variant_name} {variant2Obj.variant_name}</div>
                      ) : null}
                      Harga : 
                      <b class='card-variant'>
                        {product.discount > 0 ? (<span className='discount-price'>{currencyFormat(productPrice)}</span>) : null} {currencyFormat(parseInt(productPrice)-(parseInt(productPrice)*parseInt(product.discount)/100))}
                      </b>
                    </span>
                  </div>
                </div>
              </div>

              {
                product.product_variants && product.product_variants.length > 0 ? (
                  <>
                    <div class="col-sm-6">
                      <div class='title-card-label m-top-10'>Pilih Varian</div>
                    </div>
                    <div class="col-sm-6">
                      <div className='form-group'>
                        <select class="form-control" value={variant1} onChange={e => (loadVariant(e.target.value))} >
                          {
                            product.product_variants.filter(o => o.parent_id === null).sort((a, b) => a.id - b.id).map((p) => {
                              return (
                                <option key={p.id} value={p.sku}>{p.variant_name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </>
                ) : null
              }

              { data.mitra === true ? (
                <>
                  <div class="col-sm-6">
                    <div class='title-card-label m-top-10'>Harga Member</div>
                  </div>
                  <div class="col-sm-6">
                    <div className='form-group'>
                      <Input type='number' placeholder="Harga Jual User" value={storePrice} onChange={e => setStorePrice(e.target.value)} />
                    </div>
                  </div>
                </>
              ) : null}

              {
                haveVariant2 && variants2 ? (
                  <>
                    <div class="col-sm-6">
                      <div class='title-card-label m-top-10'>Pilih Varian 2</div>
                      </div>
                    <div class="col-sm-6">
                      <div className='form-group'>
                        <select class="form-control" value={variant2} onChange={e => (setLoadVariant2(e.target.value))} >
                          {
                            variants2.map((p) => {
                              return (
                                <option key={p.id} value={p.sku}>{p.variant_name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </>
                ) : null
              }

              <div class="col-sm-6">
                <div class='title-card-label m-top-10'>Jumlah</div>
              </div>
              <div class="col-sm-6">
                <div className='form-group'>
                  <div class='input-group'>
                    <button class='btn-increament btn btn-default' style={{marginTop: 0, background: (currentCompany.company_color3)}} onClick={IncrementItemModal}>+</button>
                    <input placeholder="Jumlah" type="number" min='1' class="validate input-border form-control text-center" value={quantity} onChange={e => setQuantity(e.target.value)} />
                    <button class='btn-increament btn btn-default' style={{marginTop: 0, background: (currentCompany.company_color3)}} onClick={DecreaseItemModal}>-</button>
                  </div>
                </div>
              </div>

              <div class="col-sm-12">
                { errorMessages ? (
                  <div class="error-card">
                    <span>{errorMessages}</span>
                  </div>
                ) : null}
                { spinner ? (
                  <div class="col-sm-12" >
                    <center><Dots color='#444' /></center>
                  </div>
                ) : null }
              </div>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <div className='row' style={{width:'100%'}}>
            <div className='col-sm-6'>
              <Button className='full-width' color="secondary" onClick={(e) => toggleItem(e)}>Cancel</Button>
            </div>
            <div className='col-sm-6'>
              {  showBoxCart && orderItem && product ? (
              <Button className='full-width' color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => editCart(orderItem.id)}>Ubah Pemesanan</Button>  
              ) : null}
            </div>
          </div>
        </ModalFooter>
      </Modal>


      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow mb-20 to-print2">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-7'>
                    <h2>
                      FAKTUR {data.code}
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem href="#" onClick={(e) => toggle(data.id)}>
                            Ubah
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </h2>
                    <label style={styles.status_payment(data.status_payment)}>{data.status_payment_txt}</label>
                  </div>
                  <div className='col-5'>
                    <div className='row'>
                      <div className='col-12'>
                        {/* <button className='btn btn-default hide-print btn-act-order' style={{marginTop: 0, float: 'right', background: (currentCompany.company_color3)}} onClick={printContent}>Print</button> */}
                        {
                          data.code ? (
                            <>
                              <a href={getNotePaymentLinkMini(data.code)} className="btn btn-default hide-print btn-act-order" style={{background: (currentCompany.company_color3), float: 'right'}} target='_blank'>
                                Print
                              </a>
                              <a href={getNotePaymentLink(data.code)} className="btn btn-accept hide-print btn-act-order" style={{background: '#444', float: 'right', color: 'white', marginRight: 5}} target='_blank'>
                                Print A5
                              </a>
                            </>
                          ) : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className='row'>
                  <div className='col-md-12'>
                    {/* <b>{data.in_outlet === true ? 'PESAN DI TEMPAT' : 'PENGIRIM'}</b>
                    <br/> */}
                    <b>{data.store_name}</b>
                    <div>
                      Kontak ({data.store_number})
                      {
                        data.store_address ? (
                          <>
                            <br/>
                            {data.store_address}
                          </>
                        ) : null
                      }
                    </div> 
                    <br/>
                  </div>
                  <div className='col-md-6 order-show-col'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <b>Pemesan</b>
                        <br/>
                      </div>
                      <div className='col-md-2'>
                        No 
                      </div>
                      <div className='col-md-10'>
                        : {data.code}
                      </div>
                      <div className='col-md-2'>
                        Tanggal 
                      </div>
                      <div className='col-md-10'>
                        : {data.order_date}
                      </div>
                      <div className='col-md-2'>
                        Nama 
                      </div>
                      <div className='col-md-10'>
                        : {data.customer_name}
                      </div>
                      <div className='col-md-2'>
                        No.Tlp/WA 
                      </div>
                      <div className='col-md-10'>
                        : {data.customer_phone}
                      </div>
                      {
                        data.in_outlet === false && data.customer_address ? (
                          <>
                            <div className='col-md-2'>
                              Alamat 
                            </div>
                            <div className='col-md-10'>
                              : {data.customer_address}, {data.customer_district}, {data.customer_city}, {data.customer_province}
                            </div>
                          </>
                        ) : (
                          <>
                            {
                              data.outlet_table ? (
                                <>
                                  <div className='col-md-2'>
                                    Nomor Table
                                  </div>
                                  <div className='col-md-10'>
                                    : {data.outlet_table}
                                  </div>
                                </>
                              ) : null
                            }
                          </>
                        )
                      }
                    </div>
                  </div>
                  {
                    data.in_outlet === false && data.order_detail.length > 0 && !(currentCompany.can_reservation || currentCompany.is_showroom) ? (
                      <div className='col-md-6 order-show-col'>
                        <div className='row'>
                          <div className='col-md-12'>
                            <b>PENGIRIMAN</b>
                            <br/>
                          </div>
                          <div className='col-md-2'>
                            Status 
                          </div>
                          <div className='col-md-10'>
                            : {data.expedition_name ? data.expedition_name.toUpperCase() : null} - {data.expedition_service}
                          </div>
                          <div className='col-md-2'>
                            Expedisi 
                          </div>
                          <div className='col-md-10'>
                            : <label style={styles.status(data.status_shipping)}>{data.status_shipping_txt}</label>
                          </div>
                          <div className='col-md-2'>
                            Berat 
                          </div>
                          <div className='col-md-10'>
                            : {data.expedition_weight} gram
                          </div>
                          <div className='col-md-2'>
                            No.Resi 
                          </div>
                          <div className='col-md-10'>
                            : {data.expedition_number && data.expedition_number.length > 4 ? data.expedition_number : 'Belum Ada'}
                          </div>
                        </div>
                      </div>
                    ) : null
                  }
                </div>

                <br/>
                <Table className="align-items-center table-flush" responsive>
                  <tr>
                    <td>
                      <b>NAMA PESANAN</b>
                    </td>
                    <td>
                      <b>JUMLAH</b>
                    </td>
                    <td className='text-right'>
                      <b>SATUAN</b>
                    </td>
                    <td className='text-right'>
                      <b>TOTAL</b>
                    </td>
                  </tr>
                  {
                    data.booking_order && data.booking_order.room ? (
                      <tr>
                        <td>
                          Reservasi {data?.booking_order?.room?.name}          
                          {
                            currentCompany.is_hotel ? (
                              <div className='card-variant'>
                                <span>{data?.booking_order?.start_date} s/d {data?.booking_order?.end_date} </span>
                                <br/><span>{data?.booking_order?.booking_people} Orang</span>
                              </div>
                            ) : (
                              <div className='card-variant'>
                                <span>{data?.booking_order?.start_date} {data?.booking_order?.booking_time}</span>
                              </div>
                            )
                          }           
                        </td>
                        {
                          currentCompany.is_hotel ? (
                            <>
                              <td>
                                <span>{data?.booking_order?.booking_days} Hari</span>
                              </td>
                              <td className='text-right'>
                                {currencyFormat(data?.booking_order?.booking_price/data?.booking_order?.booking_days)}
                              </td>
                            </>
                          ) : (
                            <>
                              <td>
                                <span>{data?.booking_order?.booking_people} Orang</span>
                              </td>
                              <td className='text-right'>
                                {currencyFormat(data?.booking_order?.booking_price)}
                              </td>
                            </>
                          )
                        }
                        <td className='text-right'>
                          {currencyFormat(data?.booking_order?.booking_price)}
                        </td>
                      </tr>
                    ) : null
                  }
                  {
                    data.order_detail ? (
                      data.order_detail.map((oi) => {
                        return(
                          <tr>
                            <td>
                              {/* <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem onClick={(e) => toggleItem(oi.id)}>
                                    Ubah
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown> */}
                              {oi.product_name}
                              <br/>
                              { oi.variant_name_1 ? (
                                <>
                                  Pilihan Varian: {oi.variant_name_1} {oi.variant_name_2}
                                </>
                              ) : null }
                              {/* <UncontrolledDropdown>
                                <DropdownToggle
                                  className="btn-icon-only text-light hide-print"
                                  href="#pablo"
                                  role="button"
                                  size="sm"
                                  color=""
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                  <DropdownItem onClick={(e) => setOrderToUpdate(oi.id, oi.order_item_code_arr)}>
                                    Tambah Kode Produk
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown> */}
                              
                              {
                                oi.order_item_codes.map((oc) => {
                                  return (
                                    <label className='label label-default' style={{marginRight: 2, marginTop: 5}}>
                                      {oc.code}
                                    </label>
                                  )
                                })
                              }
                              
                              { oi.id === orderItemId ? (
                                <div className='row' style={{marginTop:10}}>
                                  <div className='col-sm-9'>
                                    <CreatableSelect
                                      isMulti
                                      defaultValue={oi.order_item_code_arr}
                                      onChange={updateCode}
                                      options={productCodes}
                                    />
                                  </div>
                                  <div className='col-sm-3'>
                                    <Button className='full-width' style={{height: 38, lineHeight: 0}} color="secondary" onClick={(e) => editUpdateOrderCode(oi.id)}>Save</Button>
                                  </div>
                                </div>
                              ) : null }
                              
                            </td>
                            <td>
                              {oi.quantity}
                            </td>
                            <td className='text-right'>
                              {/* { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <>
                                  Harga HPP : {numberFormat(oi.basic_price)}
                                  <br/>
                                </>
                              ) } */}

                              { currentUser && currentUser.type !== 'Merchant' ? (
                                <>
                                  { oi.discount > 0 ? (
                                    <>
                                      <span className='discount-price'>{numberFormat(parseInt(oi.store_price)+(parseInt(oi.discount)/parseInt(oi.quantity)))}</span>&nbsp;
                                    </>
                                  ) : null }
                                  {data.mitra ? 'Harga Member :' : null} {numberFormat(oi.store_price)}

                                  { data.dropship && data.mitra === true ? (
                                    <>
                                      <br/>
                                      Harga Konsumen : {numberFormat(oi.sell_price)}
                                    </>
                                  ) : null}
                                </>
                              ) : null }
                            </td>
                            <td className='text-right'>
                              {/* { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                                <>
                                  Harga HPP : {numberFormat(parseInt(oi.basic_price)*parseInt(oi.quantity))}
                                  <br/>
                                </>
                              ) } */}

                              { currentUser && currentUser.type !== 'Merchant' ? (
                                <>
                                  {data.mitra ? 'Harga Member :' : null} 
                                  {numberFormat(parseInt(oi.store_price)*parseInt(oi.quantity))}
                                  { data.dropship && data.mitra === true ? (
                                    <>
                                      <br/>
                                      Harga Konsumen : {numberFormat(parseInt(oi.sell_price)*parseInt(oi.quantity))}
                                    </>
                                  ) : null}
                                </>
                              ) : null }
                            </td>
                          </tr>
                        )
                      })
                    ) : null
                  }
                  <tr>
                    <td></td>
                    <td></td>
                    <td className='text-right'>
                      <b>TOTAL :</b>
                    </td>
                    <td className='text-right'>
                      {/* { currentUser && currentUser.role_access && currentUser.role_access !== 'finance' ? null : (
                        <>
                          Harga HPP : {numberFormat(parseInt(data.basic_price))}
                          <br/>
                        </>
                      ) } */}

                      { currentUser && currentUser.type !== 'Merchant' ? (
                        <>
                          {data.mitra ? 'Harga Member :' : null} {numberFormat(parseInt(data.store_price))}
                          { data.dropship && data.mitra === true ? (
                            <>
                              <br/>
                              Harga Konsumen : {numberFormat(parseInt(data.sell_price))}
                            </>
                          ) : null}
                        </>
                      ) : null }
                    </td>
                  </tr>
                  {
                    data.in_outlet === false && data.shipping_price > 0 ? (
                      <tr>
                        <td></td>
                        <td></td>
                        <td className='text-right'>
                          <b>ONGKOS KIRIM :</b>
                        </td>
                        <td className='text-right'>
                          {numberFormat(data.shipping_price)}
                        </td>
                      </tr>
                    ) : null
                  }

                  { currentUser && currentUser.type !== 'Merchant' ? (
                    <>
                      { data.uniq_code > 0 ? (
                        <tr>
                          <td></td>
                          <td></td>
                          <td className='text-right'>
                            <b>KODE UNIK :</b>
                          </td>
                          <td className='text-right'>
                            {numberFormat(data.uniq_code)}
                          </td>
                        </tr>
                      ) : null}
                      { data.fee_cod > 0 ? (
                        <tr>
                          <td></td>
                          <td></td>
                          <td className='text-right'>
                            <b>BIAYA COD :</b>
                          </td>
                          <td className='text-right'>
                            {numberFormat(data.fee_cod)}
                          </td>
                        </tr>
                      ) : null}
                      { data.subsidi > 0 ? (
                        <tr>
                          <td></td>
                          <td></td>
                          <td className='text-right'>
                            <b>POTONGAN SUBSIDI :</b>
                          </td>
                          <td className='text-right'>
                            - {numberFormat(data.subsidi)}
                          </td>
                        </tr>
                      ) : null}
                      { data.virtual_money > 0 ? (
                        <tr>
                          <td></td>
                          <td></td>
                          <td className='text-right'>
                            <b>BAYAR PAKAI SALDO :</b>
                          </td>
                          <td className='text-right'>
                            - {numberFormat(data.virtual_money)}
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td></td>
                        <td></td>
                        <td className='text-right'>
                          <b>TOTAL PEMBAYARAN :</b>
                        </td>
                        <td className='text-right'>
                          <b>{numberFormat((parseInt(data.store_price)+parseInt(data.total_shipping_price)+parseInt(data.uniq_code)-parseInt(data.subsidi)-parseInt(data.virtual_money)))}</b>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td className='text-right'>
                          <b>METODE PEMBAYARAN :</b>
                        </td>
                        <td className='text-right'>
                          <b>{data.payment_method}</b>
                        </td>
                      </tr>
                      {
                        data.to_account_id ? (
                          <tr>
                            <td></td>
                            <td></td>
                            <td className='text-right'>
                              <b>DIBAYAR KE AKUN :</b>
                            </td>
                            <td className='text-right'>
                              {data.account_to ? `${data.account_to.name}-${data.account_to.code}` : '-'}
                            </td>
                          </tr>
                        ) : null
                      }
                      { data.dropship && data.mitra === true ? (
                        <tr>
                          <td></td>
                          <td></td>
                          <td className='text-right'>
                          <b>TOTAL PEMBAYARAN {data.customer_payment_method.toUpperCase()} KONSUMEN :</b>
                          </td>
                          <td className='text-right'>
                            <b>{numberFormat(parseInt(data.sell_price)+parseInt(data.total_shipping_price))}</b>
                          </td>
                        </tr>
                      ) : null}
                    </>
                  ) : null }
                </Table>
              </CardBody>
            </Card>

            { confirmations && confirmations.length > 0 ? (
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className='row'>
                    <div className='col-sm-12'>
                      <b>LIST KONFIRMASI PEMBAYARAN</b>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        { currentUser && (currentUser.role_access === 'finance' || 
                          currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster') ? (
                          <th scope="col">Status</th>
                        ) : null } 
                        <th scope="col">Bukti Pembayaran</th>
                        <th scope="col">Kode Konfirmasi</th>
                        <th scope="col">Kode Pesanan</th>
                        <th scope="col">Nominal</th>
                        <th scope="col">Dibayarkan</th>
                        <th scope="col">Keterangan</th>
                        <th scope="col">Dibuat</th>
                      </tr>
                    </thead>
                    <tbody>
                      { spinner ? (
                        <tr>
                          <td colspan="11" className='text-center'>
                            <div className='loadLoading'><Dots color='#444' /></div>
                          </td>
                        </tr>
                      ) : confirmations && confirmations.length > 0 ? (
                        <>
                          {
                            confirmations.map((u) => {
                              return (
                                <tr key={u.id}>
                                  { currentUser && (currentUser.role_access === 'finance' || 
                                    currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster') ? (
                                      <td>
                                        <div style={styles.status_button(u.verified)}>
                                          {u.verified ? (
                                            <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id)}></i>
                                          ) : (
                                            <i class="fa-big fa fa-power-off" onClick={(e) => changeVerified(u.id)}></i>
                                          )}
                                        </div>
                                      </td>
                                  ) : null }
                                  <td><img alt="..." src={u.image_url} style={{width:150}}/></td>
                                  <td>
                                    <b>{u.code}</b>
                                  </td>
                                  <td>
                                    <Link to={'order/'+u.order_id} >{u.invoice_code}</Link>
                                  </td>
                                  <td>
                                    {currencyFormat(u.price)}
                                    <br/>
                                    {
                                      u.verified === true ? (
                                        <span style={{color: 'green'}}>Disetujui</span>
                                      ) : (
                                        <span style={{color: 'orange'}}>Pemeriksaan</span>
                                      )
                                    }
                                  </td>
                                  <td>{u.paid_date}</td>
                                  <td>{u.from_account} - {u.to_account}</td>
                                  <td>{u.created_at}</td>
                                </tr>
                              )
                            })
                          }
                        </>
                      ) : (
                        <div className='col col-md-12 input-field2'>
                          <span>Data Tidak Ditemukan</span>
                        </div>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            ) : null}
            
            { activities && activities.length > 0 ? (
              <Card className="shadow">
                <CardHeader className="border-0">
                  <div className='row'>
                    <div className='col-sm-12'>
                      <b>RIWAYAT PERUBAHAN</b>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Table className="align-items-center table-flush" responsive>
                    {
                      activities.map((a) => {
                        return(
                          <tr>
                            <td>
                              <i className="ni ni-circle-08 text-blue-new" style={{color: (currentCompany.company_color1)}}></i> {a.title} oleh <b>{a.username}</b>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </Table>
                </CardBody>
              </Card>
            ) : null}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OrderShow;

const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    background: status === 'pending' ? 'orange' : (status === 'shipped' || status === 'received') ? 'green' : status === 'packing' ? '#11cdef' : 'red' ,
    borderRadius: 2,
    color: 'white',
    paddingRight: 5,
    paddingLeft: 5
  }),
  status_payment: (status) => ({
    fontSize: 11,
    background: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : 'red' ,
    borderRadius: 2,
    color: 'white',
    paddingRight: 5,
    paddingLeft: 5,
    marginBottom: 0
  }),
  status_button: (status) => ({
    fontSize: 11,
    color: status === true ? '#1963da' : '#ccc',
    paddingBottom: 2,
    borderRadius: 5,
  })
}