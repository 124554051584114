import React, { useEffect, useState } from 'react';
import API from '../../utils/api'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ReactSession from '../../ReactSession';
import { useHistory, Link } from "react-router-dom";
import ImageUpload from 'image-upload-react';
import 'image-upload-react/dist/index.css';
import DatePicker from "reactstrap-date-picker";
import Barcode from 'react-barcode';
import QRCode from "react-qr-code";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';

import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Table,
  Container,
  Row,
  UncontrolledTooltip,
  Input,
  Modal,
  ModalHeader, ModalBody, ModalFooter,
  Button,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
  Label,
  Col, CardBody, CardTitle
} from "reactstrap";

import Header from "components/Headers/Header.js";

function currencyFormat(num) {
  return 'Rp' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function numberFormat(num) {
  return '' + parseFloat(num).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const BookingOrderIndex = () => {
  const currentCompany = ReactSession.get("current_company");
  const token = ReactSession.get("token");
  const currentUser = ReactSession.get("current_user");
  const history = useHistory();

  const [report, setReport] = useState({});
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [paging, setPaging] = useState(true);
  const [search, setSearch] = useState('');
  const [searchStartDate, setSearchStartDate] = useState('');
  const [searchEndDate, setSearchEndDate] = useState('');
  const [searchStatusShipping, setSearchStatusShipping] = useState('');
  const [searchStatusPayment, setSearchStatusPayment] = useState('');
  const [searchCategoryDate, setSearchCategoryDate] = useState('order_date');
  const [spinner, setSpinner] = useState(true);
  const [loadMore, setLoadMore] = useState(true);
  const [loadMoreButton, setLoadMoreButton] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [print, setPrint] = useState(false);
  const [staffId, setStaffId] = useState('');

  const [id, setId] = useState('');
  const [statusShipping, setStatusShipping] = useState('');
  const [statusPayment, setStatusPayment] = useState('');
  const [expeditionNumber, setExpeditionNumber] = useState('');
  const [subsidi, setSubsidi] = useState(0);
  const [expeditionName, setExpeditionName] = useState('');
  const [expeditionService, setExpeditionService] = useState('');
  const [shippingPrice, setShippingPrice] = useState('');
  const [selectOrder, setSelectOrder] = useState({});
  const [byStaffId, setByStaffId] = useState('');
  const [byStaffCommision, setByStaffCommision] = useState(0);
  const [byStaffCommisionRepeatOrder, setByStaffCommisionRepeatOrder] = useState(0);

  const [toAccountId, setToAccountId] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [customerPaymentMethod, setCustomerPaymentMethod] = useState('');
  const [spinnerSave, setSpinnerSave] = useState(false);

  const [imageFile, setImageFile] = useState();
  const [imageSrc, setImageSrc] = useState();
  const [paidDate, setPaidDate] = useState('');
  const [paymentNumbers, setPaymentNumbers] = useState([]);
  const [customerServices, setCustomerServices] = useState([]);
  const [linkDownload, setLinkDownload] = useState('');
  const [loadingExport, setLoadingExport] = useState(false);
  const [modalExport, setModalExport] = useState(false);
  const toggleExport = () => {
    setModalExport(!modalExport);
  }

  const handleImageSelect = (e) => {
    setImageSrc(URL.createObjectURL(e.target.files[0]));
    const files = e.target.files[0]
    setImageFile(files);
  }

  const [modal, setModal] = useState(false);
  const toggle = (id) => {
    if(id){
      let dt = data.filter(o => o.order_id === id)[0]
      if(dt){
        setId(id);
        setStatusShipping(dt.order.status_shipping);
        setStatusPayment(dt.order.status_payment);
        setExpeditionNumber(dt.order.expedition_number);
        setSubsidi(dt.order.subsidi);
        setExpeditionName(dt.order.expedition_name);
        setExpeditionService(dt.order.expedition_service);
        setShippingPrice(dt.order.shipping_price);
        setByStaffCommision(dt.order.staff_commision);
        setByStaffCommisionRepeatOrder(dt.order.staff_commision_repeat_order);
        setSelectOrder(dt.order);
        setImageSrc(dt.order.image_url);
        setImageFile('');
        setPaidDate(dt.order.paid_date);
        setToAccountId(dt.order.to_account_id);
        setStaffId(dt.order.staff_id);
      }
    }
    setModal(!modal);
  }

  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [errorMessage, setErrorMessage] = useState('');

  const statusCollectionShipping = [
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Dikemas', 'value':'packing'},
    {'name':'Dikirim', 'value':'shipped'},
    {'name':'Diterima', 'value':'received'},
    {'name':'Dibatalkan', 'value':'cancelled'},
    {'name':'Diretur', 'value':'retur'}
  ]

  const statusCollectionPayment = [
    {'name':'Pemeriksaan', 'value': 'pending'},
    {'name':'Dibayar', 'value':'paid'},
    {'name':'Dibatalkan', 'value':'cancelled'}
  ]

  const today = new Date();
  const [bookingOrders, setBookingOrders] = useState([]);
  const [bookingStartAt, setBookingStartAt] = useState(today);
  const [bookingEndAt, setBookingEndAt] = useState(today);
  const localizer = momentLocalizer(moment);
  const [date, setDate] = useState(today); // State for current date
  const [view, setView] = useState('month'); // State for current view

  const handlePrev = () => {
    const newDate = new Date(date);
    if (view === 'month') {
      const firstDate = new Date(newDate.getFullYear(), newDate.getMonth()-1, 1);
      const lastDate = new Date(newDate.getFullYear(), newDate.getMonth(), 0);
      const formattedFirstDate = firstDate.toLocaleDateString();
      const formattedLastDate = lastDate.toLocaleDateString();
      setBookingStartAt(formattedFirstDate);
      setBookingEndAt(formattedLastDate);
      newDate.setMonth(newDate.getMonth() - 1);
      getBookingOrders(search, formattedFirstDate, formattedLastDate, searchStatusPayment);
    } else if (view === 'week') {
      // const firstDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getWeek());
      // const lastDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getWeek());
      // const formattedFirstDate = firstDate.toLocaleDateString();
      // const formattedLastDate = lastDate.toLocaleDateString();
      // setBookingStartAt(formattedFirstDate);
      // setBookingEndAt(formattedLastDate);
      // newDate.setDate(newDate.getDate() - 7);
      // getBookingOrders(search, formattedFirstDate, formattedLastDate);
    } else if (view === 'day') {
      const firstDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() - 1);
      const lastDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() - 1);
      const formattedFirstDate = firstDate.toLocaleDateString();
      const formattedLastDate = lastDate.toLocaleDateString();
      setBookingStartAt(formattedFirstDate);
      setBookingEndAt(formattedLastDate);
      newDate.setDate(newDate.getDate() - 1);
      // getBookingOrders(search, formattedFirstDate, formattedLastDate);
    }
    setDate(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(date);
    if (view === 'month') {
      const firstDate = new Date(newDate.getFullYear(), newDate.getMonth()+1, 1);
      const lastDate = new Date(newDate.getFullYear(), newDate.getMonth()+2, 0);
      const formattedFirstDate = firstDate.toLocaleDateString();
      const formattedLastDate = lastDate.toLocaleDateString();
      setBookingStartAt(formattedFirstDate);
      setBookingEndAt(formattedLastDate);
      newDate.setMonth(newDate.getMonth() + 1);
      getBookingOrders(search, formattedFirstDate, formattedLastDate, searchStatusPayment);
    } else if (view === 'week') {
      // const firstDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getWeek());
      // const lastDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getWeek());
      // const formattedFirstDate = firstDate.toLocaleDateString();
      // const formattedLastDate = lastDate.toLocaleDateString();
      // setBookingStartAt(formattedFirstDate);
      // setBookingEndAt(formattedLastDate);
      // newDate.setDate(newDate.getDate() + 7);
      // getBookingOrders(search, formattedFirstDate, formattedLastDate);
    } else if (view === 'day') {
      const firstDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1);
      const lastDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 1);
      const formattedFirstDate = firstDate.toLocaleDateString();
      const formattedLastDate = lastDate.toLocaleDateString();
      setBookingStartAt(formattedFirstDate);
      setBookingEndAt(formattedLastDate);
      newDate.setDate(newDate.getDate() + 1);
      // getBookingOrders(search, formattedFirstDate, formattedLastDate);
    }
    setDate(newDate);
  };

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
 

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const getBookingOrders = (keyword, start_date, end_date, status) => {
    const config = {
      headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, by_status_payment: status, booking_start_at: start_date, booking_end_at: end_date, pagination: false, include: 'order,user,room,order_detail'}
    }

    API.get(`booking_order_calendars`, config)
      .then(res => {
        const data = res.data.data;
        const newItems = data.map(item => ({
          title: item.title, 
          start: new Date(item.start), 
          end: new Date(item.end)
        }));
        setBookingOrders(newItems);
        setSpinner(false);
        setData(data);
        setTotalCount(data.length);
      })
    
    const config2 = { 
      headers: { Authorization: `Bearer ${token}`}, params: {key: keyword, booking_start_at: start_date, booking_end_at: end_date, by_status_payment: status }
    }

    API.get(`report_user`, config2)
      .then(res => {
        const data = res.data.report;
        setReport(data)
      })
  }

  const updateSearch = (search) => {
    setOffset(1)
    if(search === '' ){
      setSearch('');
    }else{
      setSearch(search);
    }
    setLoadMoreButton(false);
    getBookingOrders(search, bookingStartAt, bookingEndAt, searchStatusPayment);
  }

  const statusOrderPaymentSearch = (status) => {
    setSearchStatusPayment(status)
    getBookingOrders(search, bookingStartAt, bookingEndAt, status);
  }

  const actPost = (id) => {
    setSpinnerSave(true);
    const config = { headers: { Authorization: `Bearer ${token}` } };

    const fd = new FormData();
    fd.append('status_shipping', statusShipping);
    fd.append('status_payment', statusPayment);
    fd.append('expedition_number', expeditionNumber);
    fd.append('subsidi', subsidi);
    fd.append('expedition_name', expeditionName);
    fd.append('expedition_service', expeditionService);
    fd.append('shipping_price', shippingPrice);
    fd.append('paid_date', paidDate);
    fd.append('staff_commision', byStaffCommision);
    fd.append('staff_commision_repeat_order', byStaffCommisionRepeatOrder);
    fd.append('to_account_id', toAccountId);
    fd.append('staff_id', staffId);
    fd.append('include', 'order_detail, booking_order, room, user, order_item_codes');
    if(imageFile){
      fd.append('image_transfer', imageFile, imageFile.name);
    }
    
    if(id){
      API.put(`orders/${id}`, fd, config )
        .then(res => {
          const dt = res.data.data;
          const oldData = data;
          const new_data = oldData.findIndex((od) => od.id === dt.id)
          getBookingOrders(search, bookingStartAt, bookingEndAt, searchStatusPayment);
          setId(dt.id);
          setStatusShipping(dt.status_shipping);
          setStatusPayment(dt.status_payment);
          setExpeditionNumber(dt.expedition_number);
          setSubsidi(dt.subsidi);
          setExpeditionName(dt.expedition_name);
          setExpeditionService(dt.expedition_service);
          setShippingPrice(dt.shipping_price);
          setByStaffCommision(dt.staff_commision);
          setByStaffCommisionRepeatOrder(dt.staff_commision_repeat_order);
          setImageSrc(dt.image_url);
          setImageFile('');
          setPaidDate(dt.paid_date);
          setToAccountId(dt.to_account_id);
          setStaffId(dt.staff_id);
          // loadData(search, searchStartDate, searchEndDate, searchStatusShipping, searchStatusPayment, paymentMethod, customerPaymentMethod);
          setSpinnerSave(false);
          alert('Data berhasil di ubah');
          toggle();
        })
        .catch(error => {
          setSpinnerSave(false);
          setVisible(true);
          if(error === undefined){
            setErrorMessage('Gagal, Terjadi kesalahan di server')
          }else{
            setErrorMessage(error.response.data.meta.status.message);
          }
          console.log(error.response);
        })
    }
    
  }

  const setCompanyLoad = (company_id) => {
    let config = { headers: { Authorization: `Bearer ${token}`}, params: {by_verified: true, by_company_id: company_id, by_user_type: 'Staff', by_role_access: 'cs', pagination: false }};
    API.get(`users`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setCustomerServices(data);
      })
      .catch(error => {
        if(error === undefined){
          alert('Gagal, Terjadi kesalahan di server')
        }else{
          alert(error.response.data.meta.status.message);
        }
        console.log(error.response);
      })

    config = { headers: { Authorization: `Bearer ${token}`}, params: {by_use_payment: true, by_company_id: company_id, by_status: true, pagination: false }};
    API.get(`accounts`, config)
      .then(res => {
        const data = res.data.data;
        console.log(res.data)
        setPaymentNumbers(data);
      })
  }

  const handleSelectEvent = (event) => {
    let code = event.title.split(' - ')[0];
    let id = data.filter((bo) => bo.order.code === code)[0].id;
    history.push(`order/${id}`);
  };

  useEffect(() => {
    if(token === undefined){
      history.push("/")
    }

    if(currentUser && currentUser.type === 'Company'){
      setCompanyLoad(currentUser.id);
    }else if (currentUser && currentUser.type === 'Staff'){
      setCompanyLoad(currentUser.company_id)
    }  

    let newDate = new Date(date);
    let firstDate = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    let lastDate = new Date(newDate.getFullYear(), newDate.getMonth()+1, 0);
    let formattedFirstDate = firstDate.toLocaleDateString();
    let formattedLastDate = lastDate.toLocaleDateString();
    setBookingStartAt(formattedFirstDate);
    setBookingEndAt(formattedLastDate);
    getBookingOrders("", formattedFirstDate, formattedLastDate, searchStatusPayment);
  },[])

  return (
    <>
      <Modal isOpen={modalExport} toggle={toggleExport}>
        <ModalHeader toggle={toggleExport}>Export Data Berhasil</ModalHeader>
        <ModalBody>
          { loadingExport ? (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>
              <Dots color='#fff' /> Loading
            </a>
          ) : (
            <a href={linkDownload} target="_blank" className='btn btn-success' style={{width: '100%'}}>Download File</a>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={(e) => toggleExport(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>UBAH #{selectOrder.code}</ModalHeader>
        <ModalBody className='pt-0'>
          <Alert color="warning" isOpen={visible} toggle={onDismiss}>
            {errorMessage}
          </Alert>
          <Form role="form">
            <FormGroup className="mb-3">
              <div className='row'>
                {/* {
                  selectOrder.in_outlet === false ? (
                    <>
                      { currentUser && (currentUser.role_access === 'inventory' || currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster' || currentUser.type === 'Merchant') ? (
                        <>
                          <div className='col-12'>
                            <Label className='label-form-group'>Ubah Status {selectOrder.in_outlet === true ? 'Penerimaan' : 'Pengiriman'}</Label>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-truck" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="select" value={statusShipping} onChange={e => setStatusShipping(e.target.value)}>
                                <option value=''>Ubah Status</option>
                                {
                                  statusCollectionShipping.map((c) => {
                                    return (
                                      <option key={c.value} value={c.value}>{c.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </InputGroup>
                          </div>
                        </>
                      ) : null }
                    </>
                  ) : null
                } */}

                { currentUser ? (
                  <>
                    <div className='col-12'>
                      <center>
                        <br/>
                        <div>Upload Bukti Pembayaran</div>
                        <br/>
                        <FormGroup className="form-img-select">
                          <div className='box-image-select'>
                            <ImageUpload
                              handleImageSelect={handleImageSelect}
                              imageSrc={imageSrc}
                              setImageSrc={setImageSrc}
                              style={{
                                width: 120,
                                height: 120
                              }}
                            />
                          </div>
                        </FormGroup>
                      </center>
                    </div>
                    <div className='col-6'>
                      <FormGroup>
                        <Label className='label-form-group'>Tanggal Pembayaran</Label>
                        <DatePicker options={{ yearRange: 70}} id= "example-datepicker3" value={paidDate} onChange={e => setPaidDate(e)} />
                      </FormGroup>
                    </div>
                    <div className='col-6'>
                      <Label className='label-form-group'>Akun Penerimaan</Label>
                      <InputGroup className="input-group-alternative mb-20">
                        <Input type="select" value={toAccountId} onChange={e => setToAccountId(e.target.value)}>
                          <option value=''>Pilih No.Rekening</option>
                          {
                            paymentNumbers.map((c) => {
                              return (
                                <option key={c.id} value={c.id}>{c.title+'-'+c.code}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    </div>
                  </>
                ) : null }

                { currentUser && (currentUser.role_access === 'finance' || currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster') ? (
                  <>
                    <div className='col-6'>
                      <Label className='label-form-group'>Ubah Status Pembayaran</Label>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-coins" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="select" value={statusPayment} onChange={e => setStatusPayment(e.target.value)}>
                          <option value=''>Ubah Status Pembayaran</option>
                          {
                            statusCollectionPayment.map((c) => {
                              return (
                                <option key={c.value} value={c.value}>{c.name}</option>
                              )
                            })
                          }
                        </Input>
                      </InputGroup>
                    </div>
                    {
                      (currentUser.last_package && currentUser.last_package.can_subsidi == true ) ? (
                        <div className='col-6'>
                          <Label className='label-form-group'>Potongan Subsidi</Label>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fas fa-coins" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Subsidi Belanja"
                              value={subsidi} onChange={e => setSubsidi(e.target.value)}
                            />
                          </InputGroup>
                        </div>
                      ) : null
                    }
                  </>
                ) : null }

                {
                  selectOrder.in_outlet === true ? (
                    <>
                      { currentUser && (currentUser.role_access === 'inventory' || currentUser.role_access === 'admin' || currentUser.type === 'Company' || currentUser.type === 'Adminmaster' || currentUser.type === 'Merchant') ? (
                        <>
                          <div className='col-12'>
                            <Label className='label-form-group'>Ubah Status {selectOrder.in_outlet === true ? 'Penerimaan' : 'Pengiriman'}</Label>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-truck" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input type="select" value={statusShipping} onChange={e => setStatusShipping(e.target.value)}>
                                <option value=''>Ubah Status</option>
                                {
                                  statusCollectionShipping.map((c) => {
                                    return (
                                      <option key={c.value} value={c.value}>{c.name}</option>
                                    )
                                  })
                                }
                              </Input>
                            </InputGroup>
                          </div>
                        </>
                      ) : null }
                    </>
                  ) : null
                }

              </div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          { spinnerSave ? (
            <Button color="primary" style={{background: (currentCompany.company_color3)}}>
              <Dots color='#fff' />
            </Button>
          ) : (
            <Button color="primary" style={{background: (currentCompany.company_color3)}} onClick={e => actPost(id)}>Simpan</Button>
          )}
          <Button color="secondary" onClick={(e) => toggle(e)}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className='card-stats mb-4 mb-xl-0 m-b20 card'>
            <div className='card-body'>
              <div className="big-calendar">
                <div className='rbc-toolbar'>                  
                  <span className='rbc-btn-group'>
                    <button type='button' onClick={handlePrev}>Back</button>
                    <button type='button' onClick={handleNext}>Next</button>
                  </span>
                  <h2 className='rbc-toolbar-label'>{bookingStartAt ? formatDate(bookingStartAt) : ''} - {bookingEndAt ? formatDate(bookingEndAt) : ''}</h2>
                  <span className='rbc-btn-group'>
                    <button type='button' onClick={() => handleViewChange('day')}>Hari</button>
                    <button type='button' onClick={() => handleViewChange('month')}>Bulan</button>
                  </span>
                </div>
                <Calendar
                  localizer={localizer}
                  events={bookingOrders}
                  startAccessor="start"
                  endAccessor="end"
                  date={date} // Set the current date
                  view={view}
                  defaultView={view} // Set default view
                  onNavigate={(newDate) => setDate(newDate)} // Update date on navigation
                  onView={(newView) => handleViewChange(newView)} // Update view state
                  toolbar={false}
                  views={['month', 'week', 'day']} // Ensure the views are set
                  selectable
                  onSelectEvent={handleSelectEvent}
                />
              </div>
            </div>
          </div>
          <div className="header-body" className='box-dashboard'>
            <Row>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0 m-b20">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          BOOKING
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_count ? numberFormat(report.order_count) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0 m-b20">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          OMSET
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_store ? numberFormat(parseInt(report.order_store)-parseInt(report.virtual_spendings)) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0 m-b20">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          PERKIRAAN MODAL
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_basic ? numberFormat(parseInt(report.order_basic)) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0 m-b20">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle tag="h5"className="text-uppercase text-muted mb-0">
                          MARGIN
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {report.order_store ? numberFormat(parseInt(report.order_store)-parseInt(report.order_basic)-parseInt(report.virtual_spendings ? report.virtual_spendings : 0)) : numberFormat(0)}
                        </span>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className='row'>
                  <div className='col-sm-2'>
                    <div className='form-group'>
                      <Button className='btn btn-white full-width'>TOTAL {totalCount}</Button>
                    </div>
                  </div>
                  <div className='col-sm-2'>
                    <div className='form-group'>
                      <Input type="select" value={searchStatusPayment} onChange={e => statusOrderPaymentSearch(e.target.value)}>
                        <option value=''>Status Pembayaran</option>
                        { statusCollectionPayment.map((s) => {
                          return (
                            <option value={s.value}>{s.name}</option>
                          )
                        })}
                      </Input>
                    </div>
                  </div>
                  <div className='col-sm-8'>
                    <div className='form-group'>
                      <Input placeholder="Kata Pencarian" value={search} onChange={e => updateSearch(e.target.value)}/>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <div className="list-orders to-print" style={{padding: 10}}>
                <div className="row">
                  { spinner ? (
                    <center>
                      <div className='loadLoading'><Dots color='#444' /></div>
                    </center>
                  ) : data && data.length > 0 ? (
                    <>
                      {
                        data.map((u) => {
                          return (
                            <>
                              {
                                u.order ? (
                                  <div className='list-order' key={u.order.id}>
                                    <div className='row'>
                                      <div className='col-md-4'>
                                        <div className='form-group'>
                                          <Link to={'order/'+u.order.id} className='list-order-code'>
                                            <b>#{u.order.code}</b> 
                                          </Link>
                                          <br/>
                                          Dibuat {u.order.order_date} {
                                            u.order.today_order ? (
                                              <label className='label label-red' style={{marginLeft:5}}>Hari Ini</label>
                                            ) : null
                                          }
                                          <li className='li-order-item'>
                                            <span className='list-order-name'>Reservasi {u?.room?.name} - {currencyFormat(u?.booking_price)}</span> 
                                            <br/>
                                            {
                                              currentCompany.is_hotel ? (
                                                <div className='card-variant'>
                                                  <span>{u?.start_date} s/d {u?.end_date} </span>
                                                  <br/>
                                                  <span>{u?.booking_days} Hari, {u?.booking_people} Orang</span>
                                                </div>
                                              ) : (
                                                <div className='card-variant'>
                                                  <span>{u?.start_date} {u?.booking_time}</span>
                                                  <br/>
                                                  <span>{u?.booking_people} Orang</span>
                                                </div>
                                              )
                                            }
                                          </li>
                                          {
                                            u.order.order_detail ? (
                                              u.order.order_detail.map((oi) => {
                                                return(
                                                  <li className='li-order-item'>
                                                    { oi.discount > 0 ? (
                                                      <span className='list-order-name'>
                                                        {oi.product_name} ({oi.quantity} pcs x <span className='discount-price'>{currencyFormat(parseInt(oi.store_price)+(parseInt(oi.discount)/parseInt(oi.quantity)))}</span>&nbsp; {numberFormat(oi.store_price)})
                                                      </span>
                                                    ) : (
                                                      <span className='list-order-name'>
                                                        {oi.product_name} ({oi.quantity} pcs x {numberFormat(oi.store_price)})
                                                      </span>
                                                    ) }

                                                    <br/>
                                                    { oi.variant_name_1 ? (
                                                      <>
                                                        Pilihan Varian: {oi.variant_name_1}, {oi.variant_name_2}
                                                      </>
                                                    ) : null }
                                                  </li>
                                                )
                                              })
                                            ) : null
                                          }
                                          <br/>
                                          { u.order.voucher_code ? (
                                            <>
                                              REDEEM <b>{u.order.voucher_code}</b>
                                            </>
                                          ) : null }
                                          <br/>
                                          { u.order.staff_name ? (
                                            <>
                                              CS <b>{u.order.staff_name}</b>
                                            </>
                                          ) : null }
                                          <br/>
                                          { u.order.staff_commision > 0 ? (
                                            <>
                                              Komisi Closing CS <b>{numberFormat(u.order.staff_commision)}</b>
                                              <UncontrolledDropdown>
                                                <DropdownToggle
                                                  className="btn-icon-only text-light"
                                                  href="#pablo"
                                                  role="button"
                                                  size="sm"
                                                  color=""
                                                  onClick={(e) => e.preventDefault()}
                                                >
                                                  <i className="fas fa-ellipsis-v" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                  <DropdownItem onClick={(e) => toggle(u.order_id)}>
                                                    Ubah
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                                            </>
                                          ) : null }
                                          <br/>
                                          { u.order.staff_commision_repeat_order > 0 ? (
                                            <>
                                              Komisi RO CS <b>{numberFormat(u.order.staff_commision_repeat_order)}</b>
                                              <UncontrolledDropdown>
                                                <DropdownToggle
                                                  className="btn-icon-only text-light"
                                                  href="#pablo"
                                                  role="button"
                                                  size="sm"
                                                  color=""
                                                  onClick={(e) => e.preventDefault()}
                                                >
                                                  <i className="fas fa-ellipsis-v" />
                                                </DropdownToggle>
                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                  <DropdownItem onClick={(e) => toggle(u.order_id)}>
                                                    Ubah
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                                            </>
                                          ) : null }
                                        </div>
                                      </div>
                                      
                                      <div className='col-md-4'>
                                        <div className='form-group'>
                                          <b>Pemesan</b>
                                          <br/>
                                          <div>
                                            {u.order.customer_name} ({u.order.customer_phone} )
                                            {
                                              u.order.in_outlet === true ? (
                                                <><br/>Nomor Table <b>{u.order.outlet_table}</b><br/></>
                                              ) : null
                                            }
                                            <br/>
                                            {
                                              u.order.in_outlet === false && u.order.customer_address ? (
                                                <>
                                                  <i className='fas fa-map-marker'></i> {u.order.customer_address}, {u.order.customer_district}, {u.order.customer_city}, {u.order.customer_province}
                                                </>
                                              ) : null
                                            }
                                          </div> 
                                          <br/>
                                          <div>
                                            <i className='fas fa-building'></i>  {u.order.store_name} ({u.order.store_number})
                                            {
                                              u.order.store_address ? (
                                                <>
                                                  <br/>
                                                  <i className='fas fa-map-marker'></i> {u.order.store_address}
                                                </>
                                              ) : null
                                            }
                                          </div> 
                                        </div>
                                      </div>
                                      
                                      <div className='col-md-4'>
                                        <div className='form-group'>
                                          <b>Transaksi</b>
                                          <table className='table-order'>
                                            <tr>
                                              <td>
                                                Status Pembayaran
                                              </td>
                                              <td>
                                                : <label style={styles.status_payment(u.order.status_payment)}>{u.order.status_payment_txt}</label>
                                                <UncontrolledDropdown>
                                                  <DropdownToggle
                                                    className="btn-icon-only text-light"
                                                    href="#pablo"
                                                    role="button"
                                                    size="sm"
                                                    color=""
                                                    onClick={(e) => e.preventDefault()}
                                                  >
                                                    <i className="fas fa-ellipsis-v" />
                                                  </DropdownToggle>
                                                  <DropdownMenu className="dropdown-menu-arrow" right>
                                                    <DropdownItem onClick={(e) => toggle(u.order_id)}>
                                                      Ubah
                                                    </DropdownItem>
                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                Nama Pemesan
                                              </td>
                                              <td>
                                                : {u?.order?.user?.full_name}
                                              </td>
                                            </tr>
                                            {
                                              u.order.paid_date ? (
                                                <tr>
                                                  <td>
                                                    Dibayar
                                                  </td>
                                                  <td>
                                                    : {u.order.paid_date}
                                                  </td>
                                                </tr>
                                              ) : null
                                            }
                                            <tr>
                                              <td>
                                                Subtotal
                                              </td>
                                              <td>
                                                : {currencyFormat(u.order.store_price)}
                                              </td>
                                            </tr>
                                            {
                                              u.order.in_outlet === false && u.order.total_shipping_price > 0 ? (
                                                <tr>
                                                  <td>
                                                    Total Ongkir
                                                  </td>
                                                  <td>
                                                    : {currencyFormat(u.order.total_shipping_price)}
                                                  </td>
                                                </tr>
                                              ) : null
                                            }
                                            {
                                              u.order.uniq_code > 0 ? (
                                                <tr>
                                                  <td>
                                                    Kode Unik
                                                  </td>
                                                  <td>
                                                    : {currencyFormat(u.order.uniq_code)}
                                                  </td>
                                                </tr>
                                              ) : null
                                            }
                                            {
                                              u.order.subsidi > 0 ? (
                                                <tr>
                                                  <td>
                                                    Potongan Subsidi
                                                  </td>
                                                  <td>
                                                    : - {currencyFormat(u.order.subsidi)}
                                                  </td>
                                                </tr>
                                              ) : null
                                            }
                                            { u.order.virtual_money > 0 ? (
                                              <tr>
                                                <td>
                                                  Bayar Pakai Saldo
                                                </td>
                                                <td>
                                                  : <b>- {currencyFormat(u.order.virtual_money)}</b>
                                                </td>
                                              </tr>
                                            ) : null}
                                            <tr>
                                              <td>
                                                Total Pembayaran Anda
                                              </td>
                                              <td>
                                                : <b>{currencyFormat((parseInt(u.order.store_price)+parseInt(u.order.total_shipping_price)+parseInt(u.order.uniq_code)-parseInt(u.order.subsidi)-parseInt(u.order.virtual_money)))}</b>
                                              </td>
                                            </tr>

                                            { u.order.confirmation_transfer ? (
                                              <tr>
                                                <td>
                                                  <label className='label label-default'>Sudah Upload Bukti Pembayaran</label>
                                                </td>
                                                <td>
                                                </td>
                                              </tr>
                                            ) : null
                                            }
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null
                              }
                            </>
                          )
                        })
                      }
                    </>
                  ) : (
                    <div className='list-order'>
                      <div className='row'>
                        <div className='col col-md-12 input-field2'>
                          <span>Data Tidak Ditemukan</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BookingOrderIndex;


const styles: StyleSheet = {
  status: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : (status === 'shipped' || status === 'received') ? 'green' : status === 'packing' ? '#11cdef' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  }),
  status_payment: (status) => ({
    fontSize: 11,
    color: status === 'pending' ? 'orange' : status === 'paid' ? 'green' : 'red' ,
    paddingBottom: 2,
    borderRadius: 5,
  })
}